@media (max-width: 800px) {
  .nav-containerNueva {
    display: flex;
    flex-direction: row;
    background-image: #0c0c0c;
    background: linear-gradient(
      to bottom,
      rgba(12, 12, 12, 0.2),
      rgba(12, 12, 12, 0)
    );
  }
  
  .LogoNavNueva {
    display: flex;
    width: 90%;
    align-items: center;
  }
  
  .logoNuevo {
    width: 100%;
    height: auto;
    padding: 8%;
  }
  
  .Nav-Clas {
    display: flex;
    width: 100%;
  }
  
  .mobile {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  
  .IconMobile {
    fill: white;
    justify-content: end;
    width: 25px;
    height: 30px;
    margin-top: 20px;
    margin-right: 10px;
  }
  
  .Nav-Clas1 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 25%;
  }
  
  .Nav-Clas3 {
    display: flex;
    width: 6%;
  }
  
  .Nav-Clases {
    position: absolute;
    top: 70px;
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: flex-end;
    transition: 0.5s all ease;
    color: #fff;
    font-family: Public Sans;
    font-size: 16px;
    background: rgba(4, 68, 132, 0.45);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    padding: 0;
    padding-top: 30px;
    padding-right: 15px;
    padding-bottom: 10px;
    padding-left: 20px;
    row-gap: 30px;
    list-style: none;
  }
  
  .Nav-Clas2 {
    position: inherit;
    top: 300px;
    width: 50%;
    height: auto;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
    transition: 0.5s all ease;
    padding-top: 0px;
    padding-right: 20px;
    gap: 10px;
  }
  
  .Link {
    color: inherit;
    text-decoration: inherit;
  }
  
  .language-button {
    border: 2px solid white;
    background-color: transparent;
    color: white;
    border-radius: 50%;
    transition: all 0.3s ease;
    font-size: 11px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Public Sans;
    font-size: 14px;
  }
  
  .language-button:hover {
    border-color: #ddd;
    color: #ddd;
  }
  
  .language-button.active {
    background-color: white;
    color: #225065;
  }
  
  .dropdown {
    position: relative;
    cursor: pointer;
  }
  
  .dropdown-menu {
    position: absolute;
    background: transparent;
    color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    white-space: nowrap;
    margin-top: 3px;
  }
  
  .dropdown-menu li {
    padding: 8px;
    font-family: Public Sans;
    font-size: 16px;
  }
  
  .dropdown-menu li a {
    color: #fff;
    font-family: Public Sans;
    text-decoration: none;
    font-size: 16px;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .dropdown:hover .dropdown-menu {
    display: block; /* Show the menu on hover */
  }
  
  /* Ajuste para desplazar el siguiente dropdown */
  .dropdown:hover + .dropdown {
    margin-top: 120px; /* Ajusta este valor según la altura del primer menú desplegable */
  }
  
  /* Ajuste para desplazar el siguiente enlace */
  .dropdown:hover + .Link {
    margin-top: 90px; /* Ajusta este valor según la altura del segundo menú desplegable */
  }
  
  /* Mostrar el submenú */
  .dropdown li:hover .dropdown-menu {
    display: block; /* Show the submenu on hover */
  }
  
  .submenu-item {
    display: flex;
    justify-content: flex-end; /* Alinea los elementos a la derecha */
    align-items: flex-end;
  }
  
  .submenu {
    position: absolute;
    background: transparent;
    color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    white-space: nowrap;
    text-align: flex-end; /* Alinea el texto a la derecha */
  }
  
  .submenu li {
    padding: 8px;
  }
  
  .submenu li a {
    color: #fff;
    font-family: Public Sans;
    text-decoration: none;
    font-size: 16px;
  }
  

}

@media (min-width: 801px) and (max-width: 1280px) {
  .nav-containerNueva {
    display: flex;
    flex-direction: row;
    background-image: #0c0c0c;
    background: linear-gradient(
      to bottom,
      rgba(12, 12, 12, 0.2),
      rgba(12, 12, 12, 0)
    );
  }

  .LogoNavNueva {
    display: flex;
    width: 90%;
    align-items: center;
  }

  .logoNuevo {
    width: 100%;
    height: auto;
    padding: 8%;
  }

  .Nav-Clas {
    display: flex;
    width: 20%;
  }

  .Nav-Clas1 {
    display: flex;
    justify-content: space-between;
    width: 59%;
  }

  .Nav-Clas2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    gap: 5%;
    margin-top: 1%;
  }

  .Nav-Clas3 {
    display: flex;
    width: 6%;
  }

  .Nav-Clases {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 3%;
    align-items: center;
    list-style: none;
    margin: 0;
    color: #fff;
    font-family: Public Sans;
    font-size: 14px;
  }

  .Link {
    color: inherit;
    text-decoration: inherit;
  }

  .language-button {
    border: 2px solid white;
    background-color: transparent;
    color: white;
    border-radius: 50%;
    transition: all 0.3s ease;
    font-size: 11px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Public Sans;
    font-size: 14px;
  }

  .language-button:hover {
    border-color: #ddd;
    color: #ddd;
  }

  .language-button.active {
    background-color: white;
    color: #225065;
  }

  .dropdown {
    position: relative;
    cursor: pointer;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(4, 68, 132, 0.45);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    white-space: nowrap; /* Prevents text from wrapping */
    margin-top: 3px;
    /*min-width: 160px;*/
  }

  .dropdown-menu li {
    padding: 8px 16px;
  }

  .dropdown-menu li a {
    color: #fff;
    font-family: Public Sans;
    text-decoration: none;
    font-size: 12px;
  }

  .submenu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .submenu {
    position: absolute;
    top: 0;
    left: 100%;
    background: rgba(44, 45, 46, 0.45);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    white-space: nowrap; /* Prevents text from wrapping */
    text-align: start;
    min-width: 193px;
    min-height: 120px;
  }

  .submenu li {
    padding: 8px 16px;
  }

  .submenu li a {
    color: #fff;
    font-family: Public Sans;
    text-decoration: none;
    font-size: 12px;
  }

  .mobile {
    display: none;
  }
  .IconMobile {
    display: none;
  }
}

@media (min-width: 1281px) and (max-width: 1919px) {
  .nav-containerNueva {
    display: flex;
    flex-direction: row;
    background-image: #0c0c0c;
    background: linear-gradient(
      to bottom,
      rgba(12, 12, 12, 0.2),
      rgba(12, 12, 12, 0)
    );
  }

  .LogoNavNueva {
    display: flex;
    width: 90%;
    align-items: center;
  }

  .logoNuevo {
    width: 100%;
    height: auto;
    padding: 8%;
  }

  .Nav-Clas {
    display: flex;
    width: 20%;
  }

  .Nav-Clas1 {
    display: flex;
    justify-content: space-between;
    width: 59%;
  }

  .Nav-Clas2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    gap: 5%;
    margin-top: 1%;
  }

  .Nav-Clas3 {
    display: flex;
    width: 6%;
  }

  .Nav-Clases {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 3%;
    align-items: center;
    list-style: none;
    margin: 0;
    color: #fff;
    font-family: Public Sans;
    font-size: 14px;
  }

  .Link {
    color: inherit;
    text-decoration: inherit;
  }

  .language-button {
    border: 2px solid white;
    background-color: transparent;
    color: white;
    border-radius: 50%;
    transition: all 0.3s ease;
    font-size: 11px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Public Sans;
    font-size: 14px;
  }

  .language-button:hover {
    border-color: #ddd;
    color: #ddd;
  }

  .language-button.active {
    background-color: white;
    color: #225065;
  }

  .dropdown {
    position: relative;
    cursor: pointer;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(4, 68, 132, 0.45);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 12px;
    white-space: nowrap; /* Prevents text from wrapping */
    margin-top: 3px;
    /*min-width: 160px;*/
  }

  .dropdown-menu li {
    padding: 8px 16px;
  }

  .dropdown-menu li a {
    color: #fff;
    font-family: Public Sans;
    text-decoration: none;
    font-size: 12px;
  }

  .submenu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .submenu {
    position: absolute;
    top: 0;
    left: 100%;
    background: rgba(44, 45, 46, 0.45);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    white-space: nowrap; /* Prevents text from wrapping */
    text-align: start;
    min-width: 193px;
    min-height: 120px;
  }

  .submenu li {
    padding: 8px 16px;
  }

  .submenu li a {
    color: #fff;
    font-family: Public Sans;
    text-decoration: none;
    font-size: 12px;
  }

  .mobile {
    display: none;
  }
  .IconMobile {
    display: none;
  }
}

@media (min-width: 1920px) {
  .nav-containerNueva {
    display: flex;
    flex-direction: row;
    background-image: #0c0c0c;
    background: linear-gradient(
      to bottom,
      rgba(12, 12, 12, 0.2),
      rgba(12, 12, 12, 0)
    );
  }

  .LogoNavNueva {
    display: flex;
    width: 90%;
    align-items: center;
  }

  .logoNuevo {
    width: 100%;
    height: auto;
    padding: 8%;
  }

  .mobile {
    display: none;
  }
  .IconMobile {
    display: none;
  }

  .Nav-Clas {
    display: flex;
    width: 20%;
  }

  .Nav-Clas1 {
    display: flex;
    justify-content: space-between;
    width: 59%;
  }

  .Nav-Clas2 {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    gap: 5%;
    margin-top: 1%;
  }

  .Nav-Clas3 {
    display: flex;
    width: 6%;
  }

  .Nav-Clases {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 3%;
    align-items: center;
    list-style: none;
    margin: 0;
    color: #fff;
    font-family: Public Sans;
    font-size: 18px;
  }

  .Link {
    color: inherit;
    text-decoration: inherit;
  }

  .language-button {
    border: 2px solid white;
    background-color: transparent;
    color: white;
    border-radius: 50%;
    transition: all 0.3s ease;
    font-size: 11px;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Public Sans;
    font-size: 14px;
  }

  .language-button:hover {
    border-color: #ddd;
    color: #ddd;
  }

  .language-button.active {
    background-color: white;
    color: #225065;
  }

  .dropdown {
    position: relative;
    cursor: pointer;
  }

  .dropdown-menu {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba(4, 68, 132, 0.45);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
    font-size: 15px;
    white-space: nowrap; /* Prevents text from wrapping */
    margin-top: 5px;
    /*min-width: 190px;*/
  }

  .dropdown-menu li {
    padding: 8px 16px;
  }

  .dropdown-menu li a {
    color: #fff;
    font-family: Public Sans;
    text-decoration: none;
    font-size: 15px;
  }

  .submenu-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .submenu {
    position: absolute;
    top: 0;
    left: 100%;
    background: rgba(44, 45, 46, 0.45);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    white-space: nowrap; /* Prevents text from wrapping */
    text-align: start;
    min-width: 235px;
    min-height: 128px;
  }

  .submenu li {
    padding: 8px 16px;
  }

  .submenu li a {
    color: #fff;
    font-family: Public Sans;
    text-decoration: none;
    font-size: 15px;
  }
}
