body {
  background-color: #fff;
  font-family: "Inter", sans-serif;
  margin: 0 auto;
}

.nav {
  display: flex;
  align-items: center;
  min-height: 10%;
  height: 20%;
  list-style: none;
  justify-content: space-between;
  background-color: #040404;
}

/*.logoInfo {
  width: 20%;
  height: 15%;
}*/

/*.nav-logo-container {
  width: 100px;
}*/

/*.ul-navInfo {
  margin-top: 5%;
  display: flex;
  flex-direction: row;
  list-style: none;
  justify-content: flex-start;
  cursor: pointer;
  background-color: transparent;
  margin-right: 10%;
}*/

/*.ul-menu-linea {
  display: flex;
  flex-direction: column;
  gap: 1px;
}*/

/*.linea-menu {
  height: 1px;
  border: none;
  width: 80px;
  background-color: #fff;
  margin-top: -14px;
  margin-bottom: -10px;
  margin-left: -27px;
}
.linea-menuOIL {
  height: 1px;
  border: none;
  width: 80px;
  background-color: #fff;
  margin-top: -14px;
  margin-bottom: -10px;
  margin-left: -10px;
}

.linea-menuA {
  height: 1px;
  border: none;
  width: 80px;
  background-color: #fff;
  margin-top: -14px;
  margin-bottom: -10px;
  margin-left: 10px;
}*/

/*.ul-menu2 {
  position: absolute;
  top: 10.5%;
  left: 54%;
  background: rgba(4, 68, 132, 0.45);
  padding-left: 65px;
  padding-right: 38px;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  list-style-type: none;
}*/
/*.ul-menu2 li {
  margin-top: 13px;
  margin-bottom: 15px;
}
.ul-SUBmenu {
  position: absolute;
  top: 10.5%;
  left: 54%;
  background: rgba(4, 68, 132, 0.45);
  padding-left: 65px;
  padding-right: 38px;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  list-style-type: none;
}
.ul-SUBmenu {
  margin-top: 13px;
  margin-bottom: 15px;
}
.ul-SUBmenu1 {
  position: absolute;
  top: 10.5%;
  left: 54%;
  background: rgba(4, 68, 132, 0.45);
  padding-left: 65px;
  padding-right: 38px;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  list-style-type: none;
}
.ul-SUBmenu1 {
  margin-top: 13px;
  margin-bottom: 15px;
}

.ul-SUBmenu2 {
  position: absolute;
  top: 10.5%;
  left: 54%;
  background: rgba(4, 68, 132, 0.45);
  padding-left: 65px;
  padding-right: 38px;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  list-style-type: none;
}
.ul-SUBmenu2 {
  margin-top: 13px;
  margin-bottom: 15px;
}
.ul-menu3 {
  position: absolute;
  top: 10.5%;
  left: 67%;
  background: rgba(4, 68, 132, 0.45);
  padding-left: 52px;
  padding-right: 22px;
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  list-style-type: none;
}
.ul-menu3 li {
  margin-top: 13px;
  margin-bottom: 15px;
}

.li2 {
  color: #225065;
  text-decoration: inherit;
}*/

/*.carousel-item {
  opacity: 0.3;
  transition: opacity 0.5s ease;
}

.carousel-item.active {
  opacity: 1;
}

.toggle {
  display: none; 
}  */

/*.link-nav {
  margin-right: 3rem;
  text-decoration: none;
  font-size: 18px;
  font-weight: 400;
}*/

/*.txt-redes {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}*/

/*.txt-redes-gerentes {
  display: flex;
  flex-direction: row;
  margin-top: 1%;
}*/

.linea-titulo {
  display: flex;
  flex-direction: row;
}

.linea-titulo2 {
  display: flex;
  flex-direction: row;
}

.mi-linea {
  height: 1px;
  border: none;
  width: 80px;
  margin-top: 180px;
  background-color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  margin-left: 0px;
  margin-right: 10px;
}

.nuestra-empresa {
  margin-top: 170px;
  margin-left: 0px;
  margin-bottom: -10%;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2.5px;
}

.nuestra-empresa2 {
  margin-top: 170px;
  margin-right: 420px;
  margin-left: 20px;
  color: #fff;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2.5px;
}

/*.txt-empresa-span{
  font-weight: 600;
}*/

/*.conocenos-btn{
  width: 200px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
  margin-bottom: 200px;
  background-color: transparent;
  color: #FFF;
  border: 1px solid #FFF;
  font-size: 12px;
  margin-top: 17px;
  letter-spacing: 1.5px;
}*/

/*.redes-container-empresa{
position: fixed;
right: 0%;
display: flex;
flex-direction: column;
justify-content: center;
  margin-top: 170px;
  font-size: 150%;
  color: #FFF;
  height: 25%;
  padding-right: 1%;
  padding-left:1%;
  padding-top: 1.5%;
  padding-bottom: 1%;
  background: rgba(0, 0, 0, 0.40);
  backdrop-filter: blur( 6px );
  -webkit-backdrop-filter: blur( 6px );
  gap: 10%;
  z-index: 3;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}*/

/*.ver-mas{
  border: none;
  background-color: transparent;
  font-size: 12px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 1.5px;
  color:#FFF;
  margin-top: 10px;
  margin-left: -5px;
}

.vector-flecha{
  background-image: url(./Assets/Vector\ \(1\).png);
  background-repeat: no-repeat;
  background-size: cover;
  height: 10px;
  width: 12px;
  margin-top: 18px;
  margin-left: 5px;

}

.boton-vermas{
  display: flex;
  flex-direction: row;
}*/

/*.logos-carrusel{
  margin-top: 150px;
  margin-left: 150px;
  margin-right: 150px;
}*/

.footer {
  display: flex;
  flex-direction: row;
  background-color: #27617b;
  justify-content: space-between;
  padding-right: 200px;
  padding-left: 200px;
  padding-top: 20px;
  padding-bottom: 20px;
  color: #fff;
  font-size: 10px;
  font-style: normal;
  font-weight: 300;
  line-height: 25px;
  text-align: left;
  margin-top: auto;
}

.txt2 {
  margin-top: 0%;
}

/*.cuadros-vectores{
  display: flex;
  flex-direction: column;
  margin-bottom: 70px;
}

.cuadros-vectores .tu-elemento {
  margin-bottom: -100px; 
}

.title-vector{
color: #FFF;
font-family: Public Sans;
font-size: 30px;
font-weight: 500;
letter-spacing: 1px;
}

.title-vector1{
  color: #FFF;
  font-family: Public Sans;
  font-size: 40px;
  font-weight: 500;
  letter-spacing: 1px;
  margin-top: -1%
  }*/

/*.p-vector{
  color: #FFF;
  font-family: Public Sans;
  font-size: 18px;
  font-weight: 250;
  line-height: 20px;
  letter-spacing: 1px;
  width: 320px;
  margin-top: -7%;
}

.vector2 {
  width: 500px;
  height: 350px;
  margin-left: 200px;
}*/

/*.cards-ejes{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 20px;

}

.card-ejes{
display: flex;
flex-direction: column;
  width: 250px;
height: 400px;
border-radius: 15px;
background-color: #3A93A4;
justify-content: center;
align-items: center;
text-align: center;
}*/

/*.title-vector {
  font-size: 250%;
  font-weight: 500;
}

.vector {
  width: 10px;
  height: 10px;
  margin-left: 10px;
}

.vector2 {
  width: 10px;
  height: 10px;
  margin-left: 0px;
  margin-right: 10px;
  color: #5abdcf;
}*/

/*.footer2{
  display: flex;
  flex-direction: row;
  background-color: #27617B;
  justify-content: space-between;
  padding-right: 200px;
  padding-left: 200px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-top: 152%;
  color: #FFF;
font-size: 10px;
font-style: normal;
font-weight: 300;
line-height: 25px;
text-align: left;
}*/

/*.mi-linea2 {
  height: 1px;
  border: none;
  width: 80px;
  margin-top: 180px;
  background-color: #5abdcf;
  margin-left: 10px;
}*/

/*.nuestra-empresa3 {
  margin-top: 170px;
  color: #5abdcf;
  font-size: 12px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: 2.5px;
}

.nuestra-info-txt {
  font-size: 20px;
  font-weight: 300;
  line-height: 25px;
  letter-spacing: 2.5px;
  color: #878179;
  margin-right: 50%;
  margin-bottom: 100px;
}*/

/*.react-multiple-carousel__arrow--left{
  display: none;
}

.react-multiple-carousel__arrow--right{
  display: none;
}*/

.logistica-title {
  font-size: 40px;
  font-weight: 600;
  line-height: 25px;
  letter-spacing: 0.05px;
  color: #fff;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/*.card-contacto {
  border-radius: 15px;
  background: #f4f4f4;
  width: 300px;
  height: 140px;
  flex-shrink: 0;
}*/

/*.container-txt-card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 130px;
  margin-top: 50px;
  text-align: left;
}*/

/*.title-direccion,
.txt-direccion {
  margin-left: 50px;
  margin-top: 20px;
}*/

/*.title-direccion {
  color: #00448a;
  font-family: Public Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: -10px;
}*/

/*.txt-direccion {
  color: #656565;
  font-family: Public Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}*/

/*.my-linea {
  width: 100px;
  margin-left: 0;
}*/

/*.parapegarla {
  display: flex;
  flex-direction: row;
}*/

/*.fotobotones {
  width: 450px;
  height: 290px;
  margin-top: 50px;
}*/

/*.txt-empresa-spang {
  font-weight: 600;
  font-size: 20px;
}*/

/*.container-txt-card-comb {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 130px;
  text-align: left;
  gap: 50px;
}*/

/*.combustible-txt-img {
  display: flex;
  flex-direction: row;
  margin-right: 150px;
  gap: 50px;
}*/

/*.txt-comb-card {
  color: #656565;
  font-family: Public Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
  margin-top: -40px;
}*/

/*.titulo-linea-p-comb {
  display: flex;
  flex-direction: column;
  text-align: left;
}*/

/*.container-completo {
  display: flex;
  flex-direction: row;
}*/

/*.container-gris {
  display: flex;
  flex-direction: column;
  gap: 70px;
  padding-left: 130px;
  padding-right: 100px;
  padding-bottom: 100px;
  background: #f4f4f4;
  margin-top: 100px;
  padding-top: 50px;
}*/

/*.home-container10 {
  background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
    url(./Assets/image\ 104.png);
  background-size: cover;
  height: 700px;
}*/

.parrafos {
  display: flex;
  flex-direction: column;
}

/* .content{
  margin-top: 30px;
} */

.video {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 110vh;
  border: 0;
  box-sizing: border-box;
}

.tuvideo {
  width: 100%;
  height:100%;
  object-fit: cover;
  border: 0;
  box-sizing: border-box;
}

.content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
}

/*.logos-carrusel {
  max-width: 100%;
  height: auto;
  margin-top: 50px;
  margin-left: 0;
  margin-right: 0;
}

.logos-carrusel img {
  max-width: 100%;
  height: auto;
}*/

/*.botones {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-left: -150%;
  margin-top: 12%;
}*/

/*.btn-home {
  border-radius: 50%;
  color: white;
  background-color: #5abdcf;
  border: none;
}*/

@media (max-width: 800px) {

  .nuestra-empresa {
    margin-top: 170px;
    margin-left: 0px;
    margin-bottom: -10%;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2.5px;
  }
  
  .nuestra-empresa2 {
    margin-top: 170px;
    margin-right: 420px;
    margin-left: 20px;
    color: #fff;
    font-size: 10px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 2.5px;
  }

  .footer {
    display: flex;
    flex-direction: column;
    background-color: #27617b;
    justify-content: center;
    text-align: center;
    padding-right: 10px;
    padding-left: 10px;
    padding-top: 20px;
    padding-bottom: 20px;
    color: #fff;
    font-size: 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
  }

  .txt2 {
    margin-top: 0%;
  }

  .logistica-title {
    font-size: 30px;
    font-weight: 600;
    line-height: 35px;
    letter-spacing: 0.05px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .video {
    margin: 0;
    padding: 0;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    border: 0;
    box-sizing: border-box;
    object-fit: cover;
  }
  
  .tuvideo {
    width: 100%;
    height:100%;
    object-fit: cover;
    border: 0;
    box-sizing: border-box;
  }

  /* Estilos para pantallas más pequeñas */
  /*.txt-container-empresa {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 125%;
  }
  .nuestra-empresa-txt {
    font-size: 90%;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 2px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }*/

  /*.scroll-to-top {
    transition: background-color 0.3s ease;
    margin-right: 35%;
    margin-bottom: -7%;
  }

  .scroll-to-top:hover {
    background-color: #1f6a8c !important;
  }*/
}

@media (min-width: 801px) and (max-width: 1919px) {
  /*.txt-container-empresa {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 17%;
  }*/

  /*.txt-container-gerentes{
    text-align: left;
    margin-left: 7%;
    margin-top: 6%;
  }
  .nuestra-empresa-txt{
    font-size: 130%;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 2px;
    color: #FFF;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /*-webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #bbbcb8;*/
  /*}*/
  /*.txt-redes1 {
    display: flex;
    flex-direction: row;
    margin-top: -283px;
    margin-left: 2%;
  }*/

  /*.nuestra-historia-txt {
    font-size: 20px;
    font-weight: 300;
    line-height: 25px;
    letter-spacing: 2.5px;
    color: #fff;
    margin-right: 50%;
    margin-bottom: 100px;
  }*/

  /*.scroll-to-top {
    transition: background-color 0.3s ease;
    margin-right: 45%;
    margin-bottom: -1%;
  }

  .scroll-to-top:hover {
    background-color: #1f6a8c !important;
  }*/
}

@media (min-width: 1920px) {
  /*.txt-container-empresa {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 20%;
  }*/

  /*.nuestra-empresa-txt{
    font-size: 180%;
    font-weight: 300;
    line-height: 35px;
    letter-spacing: 2px;
    color: #FFF;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    /*-webkit-text-stroke-width: 0.5px;
    -webkit-text-stroke-color: #bbbcb8;*/
  /*}*/

  .footer {
    display: flex;
    flex-direction: row;
    background-color: #27617b;
    justify-content: space-between;
    padding-right: 200px;
    padding-left: 200px;
    padding-top: 40px;
    padding-bottom: 40px;
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 25px;
    text-align: left;
    margin-top: auto;
  }

  /*.txt-redes1 {
    display: flex;
    flex-direction: row;
    margin-top: -365px;
  }

  .p-vector {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 1px;
    width: 202px;
  }

  .title-vector {
    font-size: 30px;
  }
  .title-vectorQuienes {
    color: #fff;
    font-family: Public Sans;
    font-size: 50px;
    font-weight: 550;
    letter-spacing: 1px;
    margin-bottom: 111%;
  }

  .txt-container-gerentes {
    text-align: left;
    margin-left: 13%;
    margin-top: 9%;
  }*/

  /*.botones {
    display: flex;
    flex-direction: row;
    gap: 5px;
    margin-left: -240%;
    margin-top: 23%;
  }*/

  /*.scroll-to-top {
    transition: background-color 0.3s ease;
    margin-right: 47%;
    margin-bottom: -1%;
  }

  .scroll-to-top:hover {
    background-color: #1f6a8c !important;
  }*/
}

/*.icono-red-social {
  color: white;
}*/

/*.Scroll {
  width: 100%;
  background-color: #040404;
  position: absolute;
}*/

/*.timeline ul li {
  list-style-type: none;
  position: relative;
  width: 6px;
  margin: 0 auto;
  padding-top: 50px;
  background: white;
  font-family: "Poppins";
}

.timeline ul li::after {
  content: "";
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background: inherit;
}

.timeline ul li div {
  position: relative;
  bottom: 0;
  width: 400px;
  padding: 15px;
}

.timeline ul li div::before {
  content: "";
  position: absolute;
  bottom: 7px;
}

.timeline ul li:nth-child(odd) div {
  left: 20%;
}

.timeline ul li:nth-child(even) div {
  left: -140%;
}*/

/*.submenuNav-container {
  position: absolute;
  display: inline-block;
}

.link-nav-chapaNav {
  color: #fff;
  font-family: Public Sans;
  font-size: 70%;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; 
  letter-spacing: 1.8px;
  margin-right: 20px;
}*/
