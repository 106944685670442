
@media (max-width: 800px) {

  .banner-mollar {
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/PEM/fotoANCHOweb - CN NECA LAS LAJITAS-115 1.png");
    background-size: cover;
    height: 200px;
  }

  .container-elmollar {
    display: flex;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
    align-items: flex-start;
  }

  .home-container5 {
    background-size: cover;
    height: 600px;
  }

  .txt-redes-mollar {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .txt-container-mollar {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 17%;
  }

  .logo-title-linea-mollar {
    margin-top: 20px;
  }

  .p-elmollar {
    color: #6C6C6C;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: justify;
  }

  .logomollar {
    width: 190px;
    height: 80px;
    display: flex;
    justify-content: left;
    margin-top: 10px;
  }

  .my-lineaMollar {
    width: 100px;
    margin-top: 10px;
    margin-left: 0px;
  }

  .title-elmollar {
    color: #939E71;
    font-family: Public Sans;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    text-align: left;
  }

  .logo-siembra {
    display: flex;
    flex-direction: row;
  }

  .logos-siembra {
    width: 40px;
    height: 60px;
    margin-right: 20px;
    margin-top: 5px;
  }

  .logos-siembra2 {
    width: 35px;
    height: 55px;
    margin-right: 20px;
    margin-top: 10px;
  }

  .logos-siembra-text {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 20px;
  }

  .txt-siembra-c {
    text-align: left;
  }

  .txt-siembra {
    color: #6C6C6C;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  }

  .title-logo-choclo {
    color: #939E71;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 10px;
  }

  .container-txt-cardMollar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 0px;
    margin-bottom: 20px;
    text-align: left;
  }

  .mollar-card {
    color: #939E71;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .linea-mollar {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #939E71;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-mollar-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    margin-bottom: 0px;
  }

  .container-cards-mollar {
    display: flex;
    flex-direction: column;
    gap: 15px;
    margin-top: 20px;
  }

  .card-mollar {
    border-radius: 15px;
    background: #F4F4F4;
    width: auto;
    height: auto;
    flex-shrink: 0;
    margin-bottom: 10px;
  }

  .logistica-mollar-card {
    color: #939E71;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .title-direccion-mollar,
  .txt-direccion-mollar {
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 30px;
  }

  .title-direccion-mollar {
    color: #939E71;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: -10px;
  }

  .txt-direccion-mollar {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
  }


  .titulo-mollar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .vectormollar1 {
    height: 18px;
    width: 18px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .vectormollar4 {
    height: 20px;
    width: 20px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .vectormollar3 {
    height: 20px;
    width: 20px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .vectormollar3EN {
    height: 20px;
    width: 20px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .vectormollar2 {
    height: 15px;
    width: 20px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .txt-empresa-spanMol{
    font-weight: 600;
  }
}





@media (min-width: 801px) and (max-width: 1280px) {
  .banner-mollar {
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/PEM/fotoANCHOweb - CN NECA LAS LAJITAS-115 1.png");
    background-size: cover;
    height: 600px;
  }

  .container-elmollar {
    display: flex;
    flex-direction: row;
    margin-top: 7%;
    margin-bottom: 9%;
    margin-left: 15%;
    margin-right: 15%;
    gap: 80px
  }

  .home-container5 {
    background-size: cover;
    height: 600px;
  }

  .txt-redes-mollar {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .txt-container-mollar {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 17%;
  }

  .logo-title-linea-mollar {
    margin-top: 40px;
  }

  .p-elmollar {
    color: #6C6C6C;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }

  .logomollar {
    width: 288px;
    height: 82px;
  }

  .my-lineaMollar {
    width: 100px;
    margin-left: 0%;
    margin-top: 2%;
  }

  .title-elmollar {
    color: #939E71;
    font-family: Public Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    text-align: left;
    white-space: nowrap;
  }

  .logo-siembra {
    display: flex;
    flex-direction: row;
  }

  .logos-siembra {
    width: 40px;
    height: 60px;
    margin-right: 20px;
    margin-top: 5px;
  }

  .logos-siembra2 {
    width: 35px;
    height: 55px;
    margin-right: 20px;
    margin-top: 10px;
  }

  .logos-siembra-text {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 20px;
  }

  .txt-siembra-c {
    text-align: left;
  }

  .txt-siembra {
    color: #6C6C6C;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  }

  .title-logo-choclo {
    color: #939E71;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 10px;
  }

  .container-txt-cardMollar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 5%;
    margin-bottom: 9%;
    text-align: left;
  }

  .mollar-card {
    color: #939E71;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .linea-mollar {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #939E71;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-mollar-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .container-cards-mollar {
    display: flex;
    flex-direction: row;
    gap: 15px;
    margin-top: 50px;
    margin-right: 100px;
  }

  .card-mollar {
    border-radius: 15px;
    background: #F4F4F4;
    width: 245px;
    height: 120px;
    flex-shrink: 0;
  }

  .logistica-mollar-card {
    color: #939E71;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .title-direccion-mollar,
  .txt-direccion-mollar {
    margin-left: 20px;
    margin-top: 20px;
  }

  .title-direccion-mollar {
    color: #939E71;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: -10px;
  }

  .txt-direccion-mollar {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
  }


  .titulo-mollar {
    display: flex;
    flex-direction: row;
  }

  .vectormollar1 {
    height: 18px;
    width: 18px;
    margin-top: 10px;
    margin-left: 90px;
  }

  .vectormollar4 {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    margin-left: 118px;
  }

  .vectormollar3 {
    height: 20px;
    width: 20px;
    margin-top: 12px;
    margin-left: 120px;
  }

  .vectormollar3EN {
    height: 20px;
    width: 20px;
    margin-top: 12px;
    margin-left: 65px;
  }

  .vectormollar2 {
    height: 15px;
    width: 20px;
    margin-top: 18px;
    margin-left: 147px;
  }

  .txt-empresa-spanMol{
    font-weight: 600;
  }
}








@media (min-width: 1281px) and (max-width: 1919px) {
  .banner-mollar {
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/PEM/fotoANCHOweb - CN NECA LAS LAJITAS-115 1.png");
    background-size: cover;
    height: 600px;
  }

  .container-elmollar {
    display: flex;
    flex-direction: row;
    margin-top: 7%;
    margin-bottom: 9%;
    margin-left: 15%;
    margin-right: 15%;
    gap: 80px
  }

  .home-container5 {
    background-size: cover;
    height: 600px;
  }

  .txt-redes-mollar {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .txt-container-mollar {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 17%;
  }

  .logo-title-linea-mollar {
    margin-top: 40px;
  }

  .p-elmollar {
    color: #6C6C6C;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }

  .logomollar {
    width: 288px;
    height: 82px;
  }

  .my-lineaMollar {
    width: 100px;
    margin-left: 0%;
    margin-top: 2%;
  }

  .title-elmollar {
    color: #939E71;
    font-family: Public Sans;
    font-size: 17px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    text-align: left;
    white-space: nowrap;
  }

  .logo-siembra {
    display: flex;
    flex-direction: row;
  }

  .logos-siembra {
    width: 40px;
    height: 60px;
    margin-right: 20px;
    margin-top: 5px;
  }

  .logos-siembra2 {
    width: 35px;
    height: 55px;
    margin-right: 20px;
    margin-top: 10px;
  }

  .logos-siembra-text {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 20px;
  }

  .txt-siembra-c {
    text-align: left;
  }

  .txt-siembra {
    color: #6C6C6C;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  }

  .title-logo-choclo {
    color: #939E71;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 10px;
  }

  .container-txt-cardMollar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 5%;
    margin-bottom: 9%;
    text-align: left;
  }

  .mollar-card {
    color: #939E71;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .linea-mollar {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #939E71;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-mollar-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .container-cards-mollar {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
    margin-right: 100px;
  }

  .card-mollar {
    border-radius: 15px;
    background: #F4F4F4;
    width: 245px;
    height: 120px;
    flex-shrink: 0;
  }

  .logistica-mollar-card {
    color: #939E71;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .title-direccion-mollar,
  .txt-direccion-mollar {
    margin-left: 20px;
    margin-top: 20px;
  }

  .title-direccion-mollar {
    color: #939E71;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 600;
    line-height: 40px;
    margin-bottom: -10px;
  }

  .txt-direccion-mollar {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
  }


  .titulo-mollar {
    display: flex;
    flex-direction: row;
  }

  .vectormollar1 {
    height: 18px;
    width: 18px;
    margin-top: 10px;
    margin-left: 90px;
  }

  .vectormollar4 {
    height: 20px;
    width: 20px;
    margin-top: 10px;
    margin-left: 118px;
  }

  .vectormollar3 {
    height: 20px;
    width: 20px;
    margin-top: 12px;
    margin-left: 120px;
  }

  .vectormollar3EN {
    height: 20px;
    width: 20px;
    margin-top: 12px;
    margin-left: 65px;
  }

  .vectormollar2 {
    height: 15px;
    width: 20px;
    margin-top: 18px;
    margin-left: 147px;
  }

  .txt-empresa-spanMol{
    font-weight: 600;
  }
}












@media (min-width: 1920px) {

  .banner-mollar {
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/PEM/fotoANCHOweb - CN NECA LAS LAJITAS-115 1.png");
    background-size: cover;
    height: 750px;
  }

  .container-elmollar {
    display: flex;
    flex-direction: row;
    margin-top: 7%;
    margin-bottom: 7%;
    margin-left: 15%;
    margin-right: 15%;
    gap: 100px
  }

  .home-container5 {
    background-size: cover;
    height: 700px;
  }

  .txt-redes-mollar {
    display: flex;
    flex-direction: row;
    margin-top: -10%;
  }

  .txt-container-mollar {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7.5%;
    margin-top: 17%;
  }

  .logo-title-linea-mollar {
    margin-top: 20px;
  }

  .p-elmollar {
    color: #6C6C6C;
    font-family: Public Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
    text-align: left;
  }

  .logomollar {
    width: 360px;
    height: 103px;
    margin-top: 20px;
  }

  .my-lineaMollar {
    width: 100px;
    margin-left: 0%;
    margin-top: 3%;
  }

  .title-elmollar {
    color: #939E71;
    font-family: Public Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 37px;
    text-align: left;
  }

  .logo-siembra {
    display: flex;
    flex-direction: row;
  }

  .logos-siembra {
    width: 40px;
    height: 60px;
    margin-right: 20px;
    margin-top: 5px;
  }

  .logos-siembra2 {
    width: 35px;
    height: 55px;
    margin-right: 20px;
    margin-top: 10px;
  }

  .logos-siembra-text {
    display: flex;
    flex-direction: row;
    gap: 40px;
    margin-top: 30px;
  }

  .txt-siembra-c {
    text-align: left;
  }

  .txt-siembra {
    color: #6C6C6C;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 10px;
  }

  .title-logo-choclo {
    color: #939E71;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 400;
    line-height: 10px;
  }

  .container-txt-cardMollar {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
  }

  .mollar-card {
    color: #939E71;
    font-family: Public Sans;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .linea-mollar {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #939E71;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-mollar-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .container-cards-mollar {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
    margin-bottom: 100px;
    margin-left: 4%;
  }

  .card-mollar {
    border-radius: 15px;
    background: #F4F4F4;
    width: 20%;
    height: 25%;
    flex-shrink: 0;
  }

  .logistica-mollar-card {
    color: #939E71;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .title-direccion-mollar,
  .txt-direccion-mollar {
    margin-left: 20px;
    margin-top: 20px;
  }

  .title-direccion-mollar {
    color: #939E71;
    font-family: Public Sans;
    font-size: 25px;
    font-weight: 600;
    line-height: 40px;
    margin-top: 12%;
    margin-left: 10%;
  }

  .txt-direccion-mollar {
    color: #656565;
    font-family: Public Sans;
    font-size: 18px;
    font-style: normal;
    font-weight: 300;
    line-height: 18px;
    margin-top: -3%;
    margin-bottom: 18%;
    margin-left: 10%;
  }


  .titulo-mollar {
    display: flex;
    flex-direction: row;
  }

  .vectormollar1 {
    height: 22px;
    width: 21px;
    margin-top: 15px;
    margin-left: 35%;
  }

  .vectormollar4 {
    height: 23px;
    width: 22px;
    margin-top: 15px;
    margin-left: 47%;
  }

  .vectormollar3 {
    height: 23px;
    width: 23px;
    margin-top: 15px;
    margin-left: 49%;
  }

  .vectormollar3EN {
    height: 23px;
    width: 23px;
    margin-top: 15px;
    margin-left: 26%;
  }

  .vectormollar2 {
    height: 18px;
    width: 22px;
    margin-top: 15px;
    margin-left: 60%;
  }

  .txt-empresa-spanMol{
  font-weight: 600;
}
}