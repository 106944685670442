 .buttonNav {
    background: transparent;
    border: 0;
    padding: 0;
    cursor: pointer;
    text-align: right;
  }

  .buttonSubMenu {
    background: transparent;
    border: 0;
    padding-top: 5px;
    padding-bottom: 10px;
    cursor: pointer;
    text-align: right;
    font-size: 16px;
    font-weight: 300;
    padding-right: 15px;
  }

  
  .buttonSubMenu-Sub {
    background: transparent;
    border: 0;
    padding-top: 5px;
    padding-bottom: 10px;
    cursor: pointer;
    text-align: right;
    font-size: 13px;
    font-weight: bold;
    padding-right: 30px;
  }
  
  .sidebar {
    position: fixed;
    right: -90%;
    top: 80px;
    width: auto;
    height: auto;
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-items: flex-end;
    transition: 0.5s all ease;
    color: #fff;
    font-family: Public Sans;
    font-size: 13px;
    font-weight: 550;
    /*background: rgba(4, 68, 132, 0.45);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);*/
    background-color: #27617B;
    padding-top: 15px;
    padding-right: 20px;
    padding-left: 20px;
    row-gap: 0px;
    list-style: none;
    z-index: 1000;
  }
  
  .sidebar.open {
    right: 0;
  }
  
  .sidebar.closed {
    right: -100%;
  }
  
  .sidebar ul {
    display: grid;
    list-style: none;
    padding: 0;
    margin: 0;
    width: 100%;
  }
  
  .sub-menu {
    position: relative;
    overflow: hidden;
    height: 0;
    transition: height 0.5s;
  }
  
  .sub-menu.open {
    height: auto;
  }
  
  .sub-menu ul {
    position: relative;
    display: grid;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .sub-menu button {
    padding-left: 52px;
  }
  
  .sub-menu button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 24px;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.35);
  }
  
  .sub-sub-menu {
    position: relative;
    overflow: hidden;
    height: 0;
    transition: height 0.5s;
  }
  
  .sub-sub-menu.open {
    height: auto;
  }
  
  .sub-sub-menu ul {
    position: relative;
    display: grid;
    padding: 0;
    margin: 0;
    list-style: none;
  }
  
  .sub-sub-menu button {
    padding-left: 72px;
  }
  
  .sub-sub-menu button::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 44px;
    transform: translateY(-50%);
    width: 5px;
    height: 5px;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.35);
  }
  
  .sidebar .material-symbols-outlined {
    font-size: 16px;
  }
  
  .sidebar i {
    font-size: 20px;
    width: 20px;
    max-width: 20px;
    min-width: 20px;
  }

  .LogoNavNueva {
    display: flex;
    width: 90%;
    align-items: center;
  }

  .ConteinerNavMobile{
    width: 100%;
    background: linear-gradient(
        to bottom,
        rgba(12, 12, 12, 0.2),
        rgba(12, 12, 12, 0)
      );
  }

  .IconMobile {
    fill: white;
    justify-content: end;
    width: 25px;
    height: 30px;
    margin-top: 25px;
    margin-right: 20px;
  }
  
  .Nav-ClasMobile {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 60%;
  }

  .Cabecera{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .TraduccionMobile{
    display: flex;
    flex-direction: row;
    padding-top: 0px;
    margin-top: 0px;
    gap: 20px;
  }

  .language-buttonMobile {
    border: 2px solid white;
    background-color: transparent;
    color: white;
    border-radius: 50%;
    transition: all 0.3s ease;
    font-size: 11px;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Public Sans;
    font-size: 14px;
    margin-bottom: 15px;
  }
  
  .language-buttonMobile.active {
    background-color: white;
    color: #225065;
  }

  .Link {
    color: inherit;
    text-decoration: inherit;
  }
  