.Scroll {
  width: 100%;
  background-color: #040404;
  position: absolute;
}
@media (max-width: 800px) {
  .txt-redesHome{
    display: flex;
    flex-direction: row;
    margin-top: 3%;
  }
  .txt-container-empresaHome{
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 80%;
  }
  .nuestra-empresa-txtHome{
      display: none;
    }

    .nuestra-empresa-txtHomeMobile{
      font-size: 21px;
      font-weight: 300;
      line-height: 28px;
      letter-spacing: 2px;
      color: #FFF;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      text-align: left;
      margin-right: 20px;
      /*-webkit-text-stroke-width: 0.5px;
      -webkit-text-stroke-color: #bbbcb8;*/
      }
    .txt-empresa-spanHome{
        font-weight: 600;
      }
      .redes-container-empresa{
        position: absolute;
        display: flex;
        flex-direction: column;
        justify-content: center;
        border-top-right-radius: 15px;
        border-bottom-right-radius: 15px;
          left: npm 0px;
          top: 100px;
          font-size: 25px;
          color: #FFF;
          height: auto;
          width: 35px;
          padding-right: 1%;
          padding-left:1%;
          padding-top:20px;
          padding-bottom: 20px;
          background: rgba(0, 0, 0, 0.40);
          backdrop-filter: blur( 6px );
          -webkit-backdrop-filter: blur( 6px );
          gap: 10%;
          z-index: 3;
        }
        .icono-red-social {
            color: white;
            margin-top: 10px;
            margin-bottom: 10px;
          }
          .recuadros{
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            text-align: left;
            font-size: 16px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 1.5px;
            color: #656565;
            margin-bottom: 30px;
            margin-top: 20px;
            flex-wrap: wrap;
            margin-left: 20px;
            margin-right: 150px;
          }

          .imgResponsiva1 {
            flex: 1;
          }
          
          
          .number{
            color:#225065;
            font-size: 55px;
            font-style: normal;
            font-weight: 600;
            line-height: 69px;
            display: flex;
            justify-content: flex-start;
          }
          
          .number1{
            color:#5ABDCF;
            font-size: 55px;
            font-style: normal;
            font-weight: 600;
            line-height: 69px;
            display: flex;
            justify-content: flex-start;
          }
          
          .number2{
            color:#3A93A4;
            font-size: 55px;
            font-style: normal;
            font-weight: 600;
            line-height: 69px;
            display: flex;
            justify-content: flex-start;
          }
          
          .number3{
            color:#225065;
            font-size: 55px;
            font-style: normal;
            font-weight: 600;
            line-height: 69px;
            display: flex;
            justify-content: flex-start;
          }
          
          .mi-linea-ver {
            border-left: 100px solid #5abdcf;
            height: 0.5px; /* Ajusta la altura según sea necesario */
            margin-left: 20px; /* Ajusta el margen según sea necesario */
          }
          
          .recuadros-span{
            font-weight: 500;
            text-align: left;
          }
          
          .containerfoto-cards{
            height: 100%;
            background-image: url("http://webcontent.cn-grupo.net/Media/Fotos/Home/OPCION1 1.png");
            background-size: cover;
            background-repeat: no-repeat;
            margin-right: 0%;
            margin-left: 0%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            flex-wrap: wrap;
            background-position-x: center;
          }
          
          .card-home{
            margin-top: 25px;
            width: 260px;
            height: 310px;
            border-radius: 15px;
            background: rgba(31, 106, 140, 0.60);
            text-align: left;
            color: #FFF;
            padding-left: 40px;
            padding-right: 40px;
            padding-top: 40px;
            font-size: 15px;
            font-weight: 400;
            line-height: 25px;
            letter-spacing: 0.5px;
            color:#FFF;
            margin-left: 20px;
            margin-right: 20px;
            margin-bottom: 50px;
          
          }
          
          .logo-home{
            background-image: url(../Assets/Group.png);
            background-repeat: no-repeat;
            background-size: cover;
            height: 44px;
            width: 44px;
          }
          
          .logo-home2{
            background-image: url(../Assets/Agro\ 3\ 1.png);
            background-repeat: no-repeat;
            background-size: cover;
            height: 44px;
            width: 44px;
          }
          
          
          .logo-home3{
            background-image: url(../Assets/Mask\ group.png);
            background-repeat: no-repeat;
            background-size: cover;
            height: 44px;
            width: 44px;
          }
          
          .title-card-home{
            font-weight: 600;
          }
          .mi-linea-home {
            height: 1px; 
            border: none; 
            width: 80px;
            margin-top: 43px;
            background-color: #FFF;
            margin-left: 0px;
            margin-right: 10px;
          }
          
          .mi-linea-home1 {
            height: 1px; 
            border: none; 
            width: 80px;
            margin-top: 20px;
            background-color: #FFF;
            margin-left: 0px;
            margin-right: 10px;
          }
          
          .mi-linea-home2 {
            height: 1px; 
            border: none; 
            width: 80px;
            margin-top: 67px;
            background-color: #FFF;
            margin-left: 0px;
            margin-right: 10px;
          }
          .my-carousel{
            display: flex;
            text-align: left;
            flex-direction: column;
            margin-left: 20px;
            margin-top: 30px;
            margin-bottom: 0px;
            padding-bottom: 40px;
          }
          
          .micarrusel-titulo{
            font-weight: 600;
            color: #3A93A4; 
          }
          .foot {
            margin: 0%;
          }
          .Logos-empresa{
            background: #f4f4f4;
            margin-bottom: 0%;
            }
            
            .Logos1{
              margin-top: -20%;
            }
            
            .imgWrapper3 {
                font-size: 0;
                text-align: center;
                margin-top: 3%;
                padding-top: 5%;
                padding-right: 15%;
                padding-left: 11%; 
            }
            .imgWrapper4 {
              font-size: 0;
              text-align: center;
              margin-top: 3%;
              padding-bottom: 15%;
              padding-right: 15%;
              padding-left: 11%;
            }
            
            .imgWrapper4 .imgResponsiva,
            .imgWrapper3 .imgResponsiva {
                display: inline-block;
                font-size: inherit;
            }
            
            .imgWrapper4 .imgResponsiva {width: 50%; max-width: 345px;}
            .imgWrapper3 .imgResponsiva {width: 50%; max-width: 345px;}
            
            .imgResponsiva img {
                height: auto;
                width: 120%;
            }
            
            .my-carousel-logos{
              display: flex;
              text-align: left;
              flex-direction: column;
              margin-left: 20px;
              margin-top: 50px;
              padding-top: 20px;
            
            }
            
            .mi-linea-carrusel-logos{
              height: 1px; 
              border: none; 
              width: 80px;
              background-color:  #3A93A4; 
              margin-left: 0px;
              margin-top: -10px;
              margin-bottom: 70px;
            }

            .mi-linea-carruselHome{
              height: 1px; 
              border: none; 
              width: 80px;
              background-color:  #3A93A4; 
              margin-left: 0px;
              margin-top: -10px;
              margin-bottom: 0px;
            }
            
            .micarrusel-titulo-logos{
              font-weight: 600;
              color: #3A93A4; 
            }
            .footer1 {
              display: flex;
              flex-direction: column;
              background-color: #27617b;
              padding-top: 5px;
              padding-bottom: 5px;
              color: #fff;
              font-size: 12px;
              font-style: normal;
              font-weight: 300;
              line-height: 25px;
              text-align: center;
              align-items: center;
            }
            .txt1{
              text-align: center;
              align-items: center;
            }
            .txt-foo {
              margin: auto; /* Centrar el texto horizontalmente */
            }

            .scroll-to-top {
              transition: background-color 0.3s ease;
              margin-right: 35%;
              margin-bottom: -7%;
            }
          
            .scroll-to-top:hover {
              background-color: #1f6a8c !important;
            }

            
  .custom-paginationHome {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 20px; /* Ajusta el espacio según sea necesario */
  }
  
  .pagination-pointHome {
    width: 8px;
    height: 8px;
    border-radius: 50%;
    border: 2px solid #3A93A4; /* Borde celeste */
    background-color: transparent;
    margin: 0 5px;
    cursor: pointer;
    transition: background-color 0.3s, border 0.3s;
  }
  
  .pagination-pointHome.active {
    background-color: #3A93A4; /* Color de fondo celeste cuando está activo */
    border-color: #3A93A4;
    width: 8px;
    height: 8px;
  }

  .SliderMobile{
    display: none;
  }

  .BarraEscritorio{
    display: none;
  }

}










@media (min-width: 801px) and (max-width: 1919px) {

  .card1Home {
    display: none;
  }

  .swiper-containerHome{
    display: none;
  }

  .nuestra-empresa-txtHomeMobile{
    display: none;
  } 

    .txt-redesHome{
        display: flex;
        flex-direction: row;
        margin-top: 3%;
      }
      .txt-container-empresaHome{
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 7%;
        margin-top: 17%;
      }
      .nuestra-empresa-txtHome{
        font-size: 130%;
        font-weight: 300;
        line-height: 28px;
        letter-spacing: 2px;
        color: #FFF;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        /*-webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: #bbbcb8;*/
        }
        .txt-empresa-spanHome{
            font-weight: 600;
          }
          .redes-container-empresa{
            position: fixed;
            right: 0%;
            display: flex;
            flex-direction: column;
            justify-content: center;
              margin-top: 170px;
              font-size: 150%;
              color: #FFF;
              height: 25%;
              padding-right: 1%;
              padding-left:1%;
              padding-top: 1.5%;
              padding-bottom: 1%;
              background: rgba(0, 0, 0, 0.40);
              backdrop-filter: blur( 6px );
              -webkit-backdrop-filter: blur( 6px );
              gap: 10%;
              z-index: 3;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }
            .icono-red-social {
                color: white;
              }
              .recuadros{
                display: flex;
                flex-direction: center;
                text-align: left;
                font-size: 15px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 1.5px;
                color: #656565;
                margin-bottom: 100px;
                margin-top: 100px;
                flex-wrap: wrap;
                padding-left: 13.5%;
                padding-right: 7%;
              }
              
              .recuadros .imgResponsiva1 {
                width: 22%; 
                max-width: 345px; 
                display: inline-block; 
                font-size: inherit;
              }
              
              .imgResponsiva1 img {
                  height: auto;
                  width: 100%;
                  padding: 1%;
              }
              
              
              .number{
                color:#225065;
                font-size: 55px;
                font-style: normal;
                font-weight: 600;
                line-height: 69px;
              }
              
              .number1{
                color:#5ABDCF;
                font-size: 55px;
                font-style: normal;
                font-weight: 600;
                line-height: 69px;
              }
              
              .number2{
                color:#3A93A4;
                font-size: 55px;
                font-style: normal;
                font-weight: 600;
                line-height: 69px;
              }
              
              .number3{
                color:#225065;
                font-size: 55px;
                font-style: normal;
                font-weight: 600;
                line-height: 69px;
              }
              
              .mi-linea-ver{
                border-left: 1px solid#5ABDCF;
                border-right: none;
                margin-right: 2%;
                margin-left: 1%;
              }
              
              .recuadros-span{
                font-weight: 500;
              }
              
              .containerfoto-cards{
                height: 100%;
                background-image: url("http://webcontent.cn-grupo.net/Media/Fotos/Home/OPCION1 1.png");
                background-size: cover;
                background-repeat: no-repeat;
                margin-right: 0%;
                margin-left: 0%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-wrap: wrap;
                background-position-x: center;
              }
              
              .card-home{
                margin-top: 40px;
                width: 260px;
                height: 310px;
                border-radius: 15px;
                background: rgba(31, 106, 140, 0.60);
                text-align: left;
                color: #FFF;
                padding-left: 40px;
                padding-right: 40px;
                padding-top: 40px;
                font-size: 15px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0.5px;
                color:#FFF;
                margin-left: 20px;
                margin-right: 20px;
                margin-bottom: 50px;
              
              }
              
              .logo-home{
                background-image: url(../Assets/Group.png);
                background-repeat: no-repeat;
                background-size: cover;
                height: 44px;
                width: 44px;
              }
              
              .logo-home2{
                background-image: url(../Assets/Agro\ 3\ 1.png);
                background-repeat: no-repeat;
                background-size: cover;
                height: 44px;
                width: 44px;
              }
              
              
              .logo-home3{
                background-image: url(../Assets/Mask\ group.png);
                background-repeat: no-repeat;
                background-size: cover;
                height: 44px;
                width: 44px;
              }
              
              .title-card-home{
                font-weight: 600;
              }
              .mi-linea-home {
                height: 1px; 
                border: none; 
                width: 80px;
                margin-top: 43px;
                background-color: #FFF;
                margin-left: 0px;
                margin-right: 10px;
              }
              
              .mi-linea-home1 {
                height: 1px; 
                border: none; 
                width: 80px;
                margin-top: 20px;
                background-color: #FFF;
                margin-left: 0px;
                margin-right: 10px;
              }
              
              .mi-linea-home2 {
                height: 1px; 
                border: none; 
                width: 80px;
                margin-top: 67px;
                background-color: #FFF;
                margin-left: 0px;
                margin-right: 10px;
              }
              .my-carousel{
                display: flex;
                text-align: left;
                flex-direction: column;
                margin-left: 100px;
                margin-top: 50px;
              
              }
              
              .micarrusel-titulo{
                font-weight: 600;
                color: #3A93A4; 
              }
              .foot {
                margin: 0%;
              }
              .Logos-empresa{
                background: #f4f4f4;
                margin-bottom: 0%;
                }
                
                .Logos1{
                  margin-top: -10%;
                }
                
                .imgWrapper3 {
                    font-size: 0;
                    text-align: center;
                    margin-top: 3%;
                    padding-top: 5%;
                    padding-right: 15%;
                    padding-left: 11%; 
                }
                .imgWrapper4 {
                  font-size: 0;
                  text-align: center;
                  margin-top: 3%;
                  padding-bottom: 8%;
                  padding-right: 15%;
                  padding-left: 11%;
                }
                
                .imgWrapper4 .imgResponsiva,
                .imgWrapper3 .imgResponsiva {
                    display: inline-block;
                    font-size: inherit;
                }
                
                .imgWrapper4 .imgResponsiva {width: 25%; max-width: 345px;}
                .imgWrapper3 .imgResponsiva {width: 25%; max-width: 345px;}
                
                .imgResponsiva img {
                    height: auto;
                    width: 120%;
                }
                
                .my-carousel-logos{
                  display: flex;
                  text-align: left;
                  flex-direction: column;
                  margin-left: 100px;
                  margin-top: 50px;
                  padding-top: 50px;
                
                }
                
                .mi-linea-carrusel-logos{
                  height: 1px; 
                  border: none; 
                  width: 80px;
                  background-color:  #3A93A4; 
                  margin-left: 0px;
                  margin-top: -10px;
                  margin-bottom: 70px;
                }
                
                .micarrusel-titulo-logos{
                  font-weight: 600;
                  color: #3A93A4; 
                }
                .footer1 {
                  display: flex;
                  flex-direction: row;
                  background-color: #27617b;
                  justify-content: space-between;
                  padding-right: 200px;
                  padding-left: 200px;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  color: #fff;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 25px;
                  text-align: left;
                  margin-top: auto;
                }
                
                .txt2 {
                  margin-top: 0%;
                }

                .scroll-to-top {
                  transition: background-color 0.3s ease;
                  margin-right: 45%;
                  margin-bottom: -1%;
                }
              
                .scroll-to-top:hover {
                  background-color: #1f6a8c !important;
                }

                .BarraMobile {
                  display: none;
                }
          
}













@media (min-width: 1920px) {

  .card1Home {
    display: none;
  }

  .swiper-containerHome{
    display: none;
  }

  .nuestra-empresa-txtHomeMobile{
    display: none;
  }  
    .txt-redesHome{
        display: flex;
        flex-direction: row;
        margin-top: 3%;
      }
      .txt-container-empresaHome{
        display: flex;
        flex-direction: column;
        text-align: left;
        margin-left: 7.5%;
        margin-top: 20%;
      }
      .nuestra-empresa-txtHome{
        font-size: 180%;
        font-weight: 300;
        line-height: 35px;
        letter-spacing: 2px;
        color: #FFF;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
        /*-webkit-text-stroke-width: 0.5px;
        -webkit-text-stroke-color: #bbbcb8;*/
        }
        .txt-empresa-spanHome{
            font-weight: 600;
          }
          .redes-container-empresa{
            position: fixed;
            right: 0%;
            display: flex;
            flex-direction: column;
            justify-content: center;
              margin-top: 170px;
              font-size: 150%;
              color: #FFF;
              height: 25%;
              padding-right: 1%;
              padding-left:1%;
              padding-top: 1.5%;
              padding-bottom: 1%;
              background: rgba(0, 0, 0, 0.40);
              backdrop-filter: blur( 6px );
              -webkit-backdrop-filter: blur( 6px );
              gap: 10%;
              z-index: 3;
              border-top-left-radius: 10px;
              border-bottom-left-radius: 10px;
            }
            .icono-red-social {
                color: white;
              }
              .recuadros{
                display: flex;
                flex-direction: center;
                text-align: left;
                font-size: 15px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 1.5px;
                color: #656565;
                margin-bottom: 100px;
                margin-top: 100px;
                flex-wrap: wrap;
                padding-left: 13.5%;
                padding-right: 10%;
              }
              
              .recuadros .imgResponsiva1 {width: 22%; max-width: 345px; display: inline-block; font-size: inherit;}
              
              .imgResponsiva1 img {
                  height: auto;
                  width: 100%;
                  padding: 1%;
              }
              
              
              .number{
                color:#225065;
                font-size: 55px;
                font-style: normal;
                font-weight: 600;
                line-height: 69px;
              }
              
              .number1{
                color:#5ABDCF;
                font-size: 55px;
                font-style: normal;
                font-weight: 600;
                line-height: 69px;
              }
              
              .number2{
                color:#3A93A4;
                font-size: 55px;
                font-style: normal;
                font-weight: 600;
                line-height: 69px;
              }
              
              .number3{
                color:#225065;
                font-size: 55px;
                font-style: normal;
                font-weight: 600;
                line-height: 69px;
              }
              
              .mi-linea-ver{
                border-left: 1px solid#5ABDCF;
                border-right: none;
                margin-right: 2%;
                margin-left: 1%;
              }
              
              .recuadros-span{
                font-weight: 500;
              }
              
              .containerfoto-cards{
                height: 100%;
                background-image: url("http://webcontent.cn-grupo.net/Media/Fotos/Home/OPCION1 1.png");
                background-size: cover;
                background-repeat: no-repeat;
                margin-right: 0%;
                margin-left: 0%;
                display: flex;
                flex-direction: row;
                justify-content: center;
                flex-wrap: wrap;
                background-position-x: center;
              
              }
              
              .card-home{
                margin-top: 40px;
                width: 260px;
                height: 410px;
                border-radius: 15px;
                background: rgba(31, 106, 140, 0.60);
                text-align: left;
                color: #FFF;
                padding-left: 40px;
                padding-right: 40px;
                padding-top: 40px;
                font-size: 15px;
                font-weight: 400;
                line-height: 25px;
                letter-spacing: 0.5px;
                color:#FFF;
                margin-left: 50px;
                margin-right: 50px;
                margin-bottom: 50px;
              
              }
              
              .logo-home{
                background-image: url(../Assets/Group.png);
                background-repeat: no-repeat;
                background-size: cover;
                height: 57px;
                width: 57px;
              }
              
              .logo-home2{
                background-image: url(../Assets/Agro\ 3\ 1.png);
                background-repeat: no-repeat;
                background-size: cover;
                height: 57px;
                width: 57px;
              }
              
              
              .logo-home3{
                background-image: url(../Assets/Mask\ group.png);
                background-repeat: no-repeat;
                background-size: cover;
                height: 57px;
                width: 57px;
              }
              
              .title-card-home{
                font-weight: 600;
                font-size: 30px;
              }

              .texto-card-home{
                font-size: 20px;
              }

              .mi-linea-home {
                height: 2px; 
                border: none; 
                width: 80px;
                margin-top: 70px;
                background-color: #FFF;
                margin-left: 0px;
                margin-right: 10px;
              }
              
              .mi-linea-home1 {
                height: 2px; 
                border: none; 
                width: 80px;
                margin-top: 20px;
                background-color: #FFF;
                margin-left: 0px;
                margin-right: 10px;
              }
              
              .mi-linea-home2 {
                height: 2px; 
                border: none; 
                width: 80px;
                margin-top: 67px;
                background-color: #FFF;
                margin-left: 0px;
                margin-right: 10px;
              }
              .my-carousel{
                display: flex;
                text-align: left;
                flex-direction: column;
                margin-left: 100px;
                margin-top: 50px;
              
              }
              
              .micarrusel-titulo{
                font-weight: 600;
                color: #3A93A4; 
              }
              .foot {
                margin: 0%;
              }
              .Logos-empresa{
                background: #f4f4f4;
                margin-bottom: 0%;
                }
                
                .Logos1{
                  margin-top: -10%;
                }
                
                .imgWrapper3 {
                    font-size: 0;
                    text-align: center;
                    margin-top: 3%;
                    padding-top: 5%;
                    padding-right: 15%;
                    padding-left: 11%; 
                }
                .imgWrapper4 {
                  font-size: 0;
                  text-align: center;
                  margin-top: 3%;
                  padding-bottom: 8%;
                  padding-right: 15%;
                  padding-left: 11%;
                }
                
                .imgWrapper4 .imgResponsiva,
                .imgWrapper3 .imgResponsiva {
                    display: inline-block;
                    font-size: inherit;
                }
                
                .imgWrapper4 .imgResponsiva {width: 25%; max-width: 345px;}
                .imgWrapper3 .imgResponsiva {width: 25%; max-width: 345px;}
                
                .imgResponsiva img {
                    height: auto;
                    width: 120%;
                }
                
                .my-carousel-logos{
                  display: flex;
                  text-align: left;
                  flex-direction: column;
                  margin-left: 100px;
                  margin-top: 50px;
                  padding-top: 50px;
                
                }
                
                .mi-linea-carrusel-logos{
                  height: 1px; 
                  border: none; 
                  width: 80px;
                  background-color:  #3A93A4; 
                  margin-left: 0px;
                  margin-top: -10px;
                  margin-bottom: 70px;
                }
                
                .micarrusel-titulo-logos{
                  font-weight: 600;
                  color: #3A93A4; 
                }
                .footer1 {
                  display: flex;
                  flex-direction: row;
                  background-color: #27617b;
                  justify-content: space-between;
                  padding-right: 200px;
                  padding-left: 200px;
                  padding-top: 20px;
                  padding-bottom: 20px;
                  color: #fff;
                  font-size: 10px;
                  font-style: normal;
                  font-weight: 300;
                  line-height: 25px;
                  text-align: left;
                  margin-top: auto;
                }
                
                .txt2 {
                  margin-top: 0%;
                }

                .scroll-to-top {
                  transition: background-color 0.3s ease;
                  margin-right: 47%;
                  margin-bottom: -1%;
                }
              
                .scroll-to-top:hover {
                  background-color: #1f6a8c !important;
                }

                .BarraMobile {
                  display: none;
                }
          
}