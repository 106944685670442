@media (max-width: 800px) {
  .home-containerCap {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    overflow: hidden;
  }

  .info-btnCapi{
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 40px;
      background-color: transparent;
      color: white;
      border: 2px solid white;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      margin-top: 0%;
      margin-left: 0%;
      text-decoration: none;
      text-align: center;
    }
  
    .info-btnCapi:hover{
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 40px;
      background-color: gray;
      color: white;
      border: 2px solid white;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
  
    .contentCapi{
      position: absolute;
      width:100%;
      height: 100%;
      top: 0;
    }
  
    .txt-redesCap {
      display: flex;
      flex-direction: row;
      margin-top: -16%;
    }
    .txt-container-empresaCap {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 8%;
      margin-top: 18%;
    }
  
    .videoCap {
      position: relative;
      width: 100%;
      height: 100%;
    }
    
    .tuvideoCap {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }











@media (min-width: 801px) and (max-width: 1549px) {

  .info-btnCapi{
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 40px;
      background-color: transparent;
      color: white;
      border: 2px solid white;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      margin-top: 0%;
      margin-left: 0%;
      text-decoration: none;
      text-align: center;
    }
  
    .info-btnCapi:hover{
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 40px;
      background-color: gray;
      color: white;
      border: 2px solid white;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
  
    .contentCapi{
      position: absolute;
      width:100%;
      height: 100%;
      top: 0;
    }
  
    .txt-redesCap {
      display: flex;
      flex-direction: row;
      margin-top: -16%;
    }
    .txt-container-empresaCap {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 8%;
      margin-top: 18%;
    }
  
    .videoCap{
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100vh;
      border: 0;
      box-sizing: border-box;
    }
    
    .tuvideoCap{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 0;
      box-sizing: border-box;
    }
  }






@media (min-width: 1550px) and (max-width: 1919px) {
  .info-btnCapi{
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 40px;
      background-color: transparent;
      color: white;
      border: 2px solid white;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      margin-top: 0%;
      margin-left: 0%;
      text-decoration: none;
      text-align: center;
    }
  
    .info-btnCapi:hover{
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 40px;
      background-color: gray;
      color: white;
      border: 2px solid white;
      font-size: 15px;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
  
    .contentCapi{
      position: absolute;
      width:100%;
      height: 100%;
      top: 0;
    }
  
    .txt-redesCap {
      display: flex;
      flex-direction: row;
      margin-top: -16%;
    }
    .txt-container-empresaCap {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 8%;
      margin-top: 17%;
    }
  
    .videoCap{
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100vh;
      border: 0;
      box-sizing: border-box;
    }
    
    .tuvideoCap{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 0;
      box-sizing: border-box;
    }
  }
  
  
  
  
  
  
  
  
  
  
  
  
  @media (min-width: 1920px) {
    .info-btnCapi{
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 40px;
      background-color: transparent;
      color: white;
      border: 2px solid white;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
      margin-top: 0%;
      margin-left: 0%;
      text-decoration: none;
      text-align: center;
    }
  
    .info-btnCapi:hover{
      width: 250px;
      padding-top: 8px;
      padding-bottom: 8px;
      border-radius: 40px;
      background-color: gray;
      color: white;
      border: 2px solid white;
      font-size: 20px;
      font-weight: 600;
      letter-spacing: 1.5px;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
    }
  
    .contentCapi{
      position: absolute;
      width:100%;
      height: 100%;
      top: 0;
    }
  
    .txt-redesCap {
      display: flex;
      flex-direction: row;
      margin-top: -10%;
    }
  
    .txt-container-empresaCap {
      display: flex;
      flex-direction: column;
      text-align: left;
      margin-left: 7%;
      margin-top: 17%;
    }
  
    .videoCap{
      margin: 0;
      padding: 0;
      width: 100%;
      height: 100vh;
      border: 0;
      box-sizing: border-box;
    }
    
    .tuvideoCap{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border: 0;
      box-sizing: border-box;
    }
  }