@media (max-width: 800px) {

  .container-cards-Guemes {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .numeritosPla {
    display: flex;
    flex-direction: row;
    gap: 20px;
    flex-wrap: wrap;
  }

  .numeritoPlanta {
    font-size: 20px;
    margin-bottom: 0;
    padding-bottom: 0;
    text-align: left;
  }

  .lineanumPlanta {
    height: 1px;
    border: none;
    width: 30px;
    background-color: #656565;
    padding-top: 0;
    margin-top: 0;
  }

  /*.numerito2 {
    margin-top: 58%
  }*/

    .txt-container-empresaGas {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 17%;
  }

  .txt-pequeño{
    margin-top: -10px;
    margin-left: -80px;
  }

  .txt-pequeñoEN{
    margin-top: -10px;
    margin-left: 10px;
  }

  .txt-pequeñoA{
    margin-top: 40px;
    margin-left: -80px;
  }

  .txt-pequeñoAEN{
    margin-top: 45px;
    margin-left: -62px;
  }
  
  .txt-pequeñoB{
    margin-top: 45px;
    margin-left: -235px;
  }

  .txt-pequeñoBEN{
    margin-top: 45px;
    margin-left: -220px;
  }

  .txt-numAPlanta{
    margin-top: 20px;
    margin-left: 10px;
    font-weight: 600;
    text-align: left;
  }

  .Planta-title-card {
    color: #00448a;
    font-family: Public Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .txt-redesGue {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .container-planta {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    gap: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .p-planta {
    text-align: justify;
    color: #6c6c6c;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
  }

  .logoplanta {
    width: 250px;
    height: 80px;
    margin-top: 4%;
    margin-right: 5%;
  }

  .banner-planta {
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/PG/DSC04779-HDR.png");
    height: 200px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .container-txt-card-planta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    text-align: left;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .seccion2planta {
    display: flex;
    flex-direction: column;
    margin-left: 20px;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .txt-empresa-spanGue {
    font-weight: 600;
  }

  .linea-Guemes {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-guemes-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .card-contactoGue {
    border-radius: 15px;
    background: #f4f4f4;
    width: auto;
    height: auto;
    flex-shrink: 0;
  }

  .titulo-logo-guemes {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  .title-direccionGuemes {
    color: #00448a;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: -10%;
  }

  .txt-direccionGuemes {
    color: #656565;
    font-family: Public Sans;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding-top: 10px;
    padding-bottom: 15px;
  }

  .title-direccionGuemes,
  .txt-direccionGuemes {
    margin-left: 10%;
    margin-top: 30px;
  }

  .vector-guemes {
    height: 20px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .vector-guemes1 {
    height: 20px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .vector-guemes2 {
    height: 18px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .vector-guemes2EN {
    height: 18px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .vector-guemes3 {
    height: 13px;
    margin-top: 15px;
    margin-right: 20px;
  }

  .numeroytxtPlanta {
    display: flex;
    flex-direction: row;
    color: #656565;
  }

  .numeroPlanta {
    text-align: left;
  }

  .numeroPlanta2 {
    text-align: left;
  }

  .container-txt-card-planta1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
    text-align: left;
  }
}








@media (min-width: 801px) and (max-width: 1280px) {

  .lineanumPlanta {
    height: 1px;
    border: none;
    width: 30px;
    background-color: #656565;
    margin-left: 0;
    margin-top: -20px;
  }

  .container-cards-Guemes {
    display: flex;
    flex-direction: row;
    gap: 20px;
    margin-top: 50px;
    margin-right: 100px;
    margin-bottom: 10%;
  }

  .numeritosPla {
    display: flex;
    flex-direction: row;
    gap: 80px;
    flex-wrap: wrap;
  }

  /*.numerito2 {
    margin-top: 58%
  }*/

    .txt-container-empresaGas {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 17%;
  }

  .txt-pequeño{
    margin-top: -10px;
    margin-left: -80px;
  }

  .txt-pequeñoEN{
    margin-top: -10px;
    margin-left: 10px;
  }

  .txt-pequeñoA{
    margin-top: 40px;
    margin-left: -80px;
  }

  .txt-pequeñoAEN{
    margin-top: 45px;
    margin-left: -62px;
  }
  
  .txt-pequeñoB{
    margin-top: 45px;
    margin-left: -235px;
  }

  .txt-pequeñoBEN{
    margin-top: 45px;
    margin-left: -220px;
  }

  .txt-numAPlanta{
    margin-top: 20px;
    margin-left: 10px;
    font-weight: 600;
    text-align: left;
  }

  .Planta-title-card {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .txt-redesGue {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .container-planta {
    display: flex;
    flex-direction: row;
    margin-left: 18%;
    margin-right: 15%;
    gap: 100px;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .p-planta {
    text-align: left;
    color: #6c6c6c;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
  }

  .logoplanta {
    width: 250px;
    height: 80px;
    margin-top: 4%;
    margin-right: 5%;
  }

  .banner-planta {
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/PG/DSC04779-HDR.png");
    height: 600px;
    background-repeat: no-repeat;
  }

  .container-txt-card-planta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
    margin-right: 600px;
    margin-bottom: 100px;
  }

  .seccion2planta {
    display: flex;
    flex-direction: column;
    margin-left: 130px;
    margin-right: 200px;
    margin-bottom: 100px;
  }

  .txt-empresa-spanGue {
    font-weight: 600;
  }

  .linea-Guemes {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-guemes-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .card-contactoGue {
    border-radius: 15px;
    background: #f4f4f4;
    width: 23%;
    height: 160px;
    flex-shrink: 0;
  }

  .titulo-logo-guemes {
    display: flex;
    flex-direction: row;
    gap: 130px;
  }

  .title-direccionGuemes {
    color: #00448a;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: -10%;
  }

  .txt-direccionGuemes {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .title-direccionGuemes,
  .txt-direccionGuemes {
    margin-left: 10%;
    margin-top: 30px;
  }

  .vector-guemes {
    height: 20px;
    margin-top: 20px;
    margin-left: -25%;
  }

  .vector-guemes1 {
    height: 20px;
    margin-top: 20px;
    margin-left: -12%;
  }

  .vector-guemes2 {
    height: 18px;
    margin-top: 20px;
    margin-left: -10%;
  }

  .vector-guemes2EN {
    height: 18px;
    margin-top: 20px;
    margin-left: -32%;
  }

  .vector-guemes3 {
    height: 13px;
    margin-top: 25px;
    margin-left: 1%;
  }

  .numeroytxtPlanta {
    display: flex;
    flex-direction: row;
    color: #656565;
  }

  .numeroPlanta {
    text-align: left;
  }

  .numeroPlanta2 {
    text-align: left;
    margin-left: -5%;
  }

  .container-txt-card-planta1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
  }
}







@media (min-width: 1281px) and (max-width: 1919px) {

  .lineanumPlanta {
    height: 1px;
    border: none;
    width: 30px;
    background-color: #656565;
    margin-left: 0;
    margin-top: -20px;
  }

  .container-cards-Guemes {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
    margin-right: 100px;
    margin-bottom: 10%;
  }

  .numeritosPla {
    display: flex;
    flex-direction: row;
    gap: 80px;
    flex-wrap: wrap;
  }

  /*.numerito2 {
    margin-top: 58%
  }*/

    .txt-container-empresaGas {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 17%;
  }

  .txt-pequeño{
    margin-top: -10px;
    margin-left: -80px;
  }

  .txt-pequeñoEN{
    margin-top: -10px;
    margin-left: 10px;
  }

  .txt-pequeñoA{
    margin-top: 40px;
    margin-left: -80px;
  }

  .txt-pequeñoAEN{
    margin-top: 45px;
    margin-left: -62px;
  }
  
  .txt-pequeñoB{
    margin-top: 45px;
    margin-left: -235px;
  }

  .txt-pequeñoBEN{
    margin-top: 45px;
    margin-left: -220px;
  }

  .txt-numAPlanta{
    margin-top: 20px;
    margin-left: 10px;
    font-weight: 600;
    text-align: left;
  }

  .Planta-title-card {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .txt-redesGue {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .container-planta {
    display: flex;
    flex-direction: row;
    margin-left: 18%;
    margin-right: 15%;
    gap: 100px;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .p-planta {
    text-align: left;
    color: #6c6c6c;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
  }

  .logoplanta {
    width: 250px;
    height: 80px;
    margin-top: 4%;
    margin-right: 5%;
  }

  .banner-planta {
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/PG/DSC04779-HDR.png");
    height: 600px;
    background-repeat: no-repeat;
  }

  .container-txt-card-planta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
    margin-right: 600px;
    margin-bottom: 100px;
  }

  .seccion2planta {
    display: flex;
    flex-direction: column;
    margin-left: 130px;
    margin-right: 200px;
    margin-bottom: 100px;
  }

  .txt-empresa-spanGue {
    font-weight: 600;
  }

  .linea-Guemes {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-guemes-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .card-contactoGue {
    border-radius: 15px;
    background: #f4f4f4;
    width: 21%;
    height: 160px;
    flex-shrink: 0;
  }

  .titulo-logo-guemes {
    display: flex;
    flex-direction: row;
    gap: 130px;
  }

  .title-direccionGuemes {
    color: #00448a;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: -10%;
  }

  .txt-direccionGuemes {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .title-direccionGuemes,
  .txt-direccionGuemes {
    margin-left: 10%;
    margin-top: 30px;
  }

  .vector-guemes {
    height: 20px;
    margin-top: 20px;
    margin-left: -25%;
  }

  .vector-guemes1 {
    height: 20px;
    margin-top: 20px;
    margin-left: -12%;
  }

  .vector-guemes2 {
    height: 18px;
    margin-top: 20px;
    margin-left: -10%;
  }

  .vector-guemes2EN {
    height: 18px;
    margin-top: 20px;
    margin-left: -32%;
  }

  .vector-guemes3 {
    height: 13px;
    margin-top: 25px;
    margin-left: 1%;
  }

  .numeroytxtPlanta {
    display: flex;
    flex-direction: row;
    color: #656565;
  }

  .numeroPlanta {
    text-align: left;
  }

  .numeroPlanta2 {
    text-align: left;
    margin-left: -5%;
  }

  .container-txt-card-planta1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
  }
}












@media (min-width: 1920px) {

  .lineanumPlanta {
    height: 1px;
    border: none;
    width: 30px;
    background-color: #656565;
    margin-left: 0;
    margin-top: -20px;
  }

  .container-cards-Guemes {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
    margin-right: 100px;
    margin-bottom: 10%;
  }

  .numeritosPla {
    display: flex;
    flex-direction: row;
    gap: 120px;
    flex-wrap: wrap;
  }

  .numerito {
    margin-top: 70%;
  }

  .numerito1 {
    margin-top: 75%;
  }

    .txt-container-empresaGas {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7.5%;
    margin-top: 22%;
  }

  .txt-pequeño{
    margin-top: -18px;
    margin-left: -165px;
    font-size: 20px;
  }

  .txt-pequeñoEN{
    margin-top: -18px;
    margin-left: 15px;
    font-size: 20px;
  }

  .txt-pequeñoA{
    margin-top: 55px;
    margin-left: -125px;
    font-size: 20px;
  }

  .txt-pequeñoAEN{
    margin-top: 55px;
    margin-left: -97px;
    font-size: 20px;
  }
  
  .txt-pequeñoB{
    margin-top: 55px;
    margin-left: -367px;
    font-size: 20px;
  }

  .txt-pequeñoBEN{
    margin-top: 55px;
    margin-left: -343px;
    font-size: 20px;
  }

  .txt-numAPlanta{
    margin-top: 22px;
    margin-left: 15px;
    font-weight: 600;
    text-align: left;
    font-size: 25px;
  }

  .Planta-title-card {
    color: #00448a;
    font-family: Public Sans;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .txt-redesGue {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .container-planta {
    display: flex;
    flex-direction: row;
    margin-left: 18%;
    margin-right: 20%;
    gap: 100px;
    margin-top: 100px;
    margin-bottom: 100px;
  }

  .p-planta {
    text-align: left;
    color: #6c6c6c;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  .logoplanta {
    width: 300px;
    height: 90px;
    margin-top: 4%;
    margin-right: 5%;
  }

  .banner-planta {
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/PG/DSC04779-HDR.png");
    height: 800px;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .container-txt-card-planta {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
    margin-right: 600px;
    margin-bottom: 100px;
  }

  .container-txt-card-planta1{
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
  }

  .seccion2planta {
    display: flex;
    flex-direction: column;
    margin-left: 130px;
    margin-right: 200px;
    margin-bottom: 100px;
  }

  .txt-empresa-spanGue {
    font-weight: 600;
  }

  .linea-Guemes {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-guemes-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 20px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .card-contactoGue {
    border-radius: 15px;
    background: #f4f4f4;
    width: 21%;
    height: 180px;
    flex-shrink: 0;
  }

  .titulo-logo-guemes {
    display: flex;
    flex-direction: row;
    gap: 130px;
  }

  .title-direccionGuemes {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: -10%;
  }

  .txt-direccionGuemes {
    color: #656565;
    font-family: Public Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
  }

  .title-direccionGuemes,
  .txt-direccionGuemes {
    margin-left: 10%;
    margin-top: 30px;
  }

  .vector-guemes {
    height: 22px;
    margin-top: 21px;
    margin-left: -1%;
  }

  .vector-guemes1 {
    height: 21px;
    margin-top: 21px;
    margin-left: 12%;
  }

  .vector-guemes2 {
    height: 21px;
    margin-top: 21px;
    margin-left: 12%;
  }

  .vector-guemes2EN {
    height: 21px;
    margin-top: 21px;
    margin-left: -9%;
  }

  .vector-guemes3 {
    height: 15px;
    margin-top: 25px;
    margin-left: 22%;
  }

  .numeroytxtPlanta {
    display: flex;
    flex-direction: row;
    color: #656565;
  }

  .numeroPlanta {
    text-align: left;
  }

  .numeroPlanta2 {
    text-align: left;
  }

  .numeroPlanta5 {
    text-align: left;
    margin-left: 21%;
  }
}
