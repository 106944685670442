
.nav-containerNuevaOsc{
    display: flex;
    flex-direction: row;
    background-image:  #0c0c0c;
    background: linear-gradient(to bottom, rgba(12, 12, 12, 0.2), rgba(12, 12, 12, 0));
  }

  .LogoNavNuevaOsc {
    display: flex;
    width: 90%;
    align-items: center;
  }
  
  .logoNuevoOsc {
    width: 100%; 
    height: auto; 
    padding: 8%;
  }

  .Nav-ClasOsc{
    display: flex;
    width: 20%;
  }

  .Nav-Clas1Osc{
    display: flex;
    justify-content: space-between;
    width: 59%;
  }

  .Nav-Clas2Osc{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 15%;
    gap: 5%;
    margin-top: 1%;
  }

  .Nav-Clas3Osc{
    display: flex;
    width: 6%;
  }

  .Nav-ClasesOsc {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    gap: 3%;
    align-items: center;
    list-style: none; 
    margin: 0; 
    color: #225065;
    font-family: Public Sans;
    font-size: 14px;
  }

  .LinkOsc{
    color: inherit;
    text-decoration: inherit;
  }

  .language-buttonOsc {
    border: 2px solid #225065;
    background-color: transparent;
    color: #225065;
    border-radius: 50%;
    transition: all 0.3s ease;
    font-size: 11px;
    width: 50px; 
    height: 50px; 
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Public Sans;
    font-size: 14px;
  }
  
  .language-buttonOsc:hover {
    border-color: #225065;
    color: white;
  }
  
  .language-buttonOsc.active {
    background-color: #225065;
    color: white;
  }

  .dropdownOsc {
    position: relative;
    cursor: pointer;
  }
  
  .dropdown-menuOsc {
    position: absolute;
    top: 100%;
    left: 50%;
    transform: translateX(-50%);
    background: rgba( 4, 68, 132, 0.45 );
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    z-index: 1000;
    font-size: 12px;
    white-space: nowrap; /* Prevents text from wrapping */
  }
  
  .dropdown-menuOsc li {
    padding: 8px 16px;
  }
  
  .dropdown-menuOsc li a {
    color: #FFF;
    font-family: Public Sans;
    text-decoration: none;
    font-size: 12px;
  }

  .submenu-itemOsc {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .submenuOsc {
    position: absolute;
    top: 0;
    left: 100%;
    background: rgba(44, 45, 46, 0.45);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    color: white;
    list-style: none;
    padding: 0;
    margin: 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    white-space: nowrap; /* Prevents text from wrapping */
    text-align: start;
    min-width: 193px;
    min-height: 120px;
  }
  
  .submenuOsc li {
    padding: 8px 16px;
  }
  
  .submenuOsc li a {
    color: #FFF;
    font-family: Public Sans;
    text-decoration: none;
    font-size: 12px;
  }

  @media (min-width: 1920px) {
    .Nav-ClasesOsc {
      display: flex;
      flex: 1;
      justify-content: flex-end;
      gap: 3%;
      align-items: center;
      list-style: none; 
      margin: 0; 
      color: #225065;
      font-family: Public Sans;
      font-size: 18px;
    }

    .dropdownOsc {
      position: relative;
      cursor: pointer;
    }
    
    .dropdown-menuOsc {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      background: rgba( 4, 68, 132, 0.45 );
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      color: white;
      list-style: none;
      padding: 0;
      margin: 0;
      z-index: 1000;
      font-size: 15px;
      white-space: nowrap; /* Prevents text from wrapping */
    }
    
    .dropdown-menuOsc li {
      padding: 8px 16px;
    }
    
    .dropdown-menuOsc li a {
      color: #FFF;
      font-family: Public Sans;
      text-decoration: none;
      font-size: 15px;
    }
  
    .submenu-itemOsc {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    
    .submenuOsc {
      position: absolute;
      top: 0;
      left: 100%;
      background: rgba(44, 45, 46, 0.45);
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      color: white;
      list-style: none;
      padding: 0;
      margin: 0;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
      white-space: nowrap; /* Prevents text from wrapping */
      text-align: start;
      min-width: 235px;
      min-height: 128px;
    }
    
    .submenuOsc li {
      padding: 8px 16px;
    }
    
    .submenuOsc li a {
      color: #FFF;
      font-family: Public Sans;
      text-decoration: none;
      font-size: 15px;
    }

    .Nav-Clas2Osc{
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15%;
      gap: 5%;
      margin-top: 0%;
    }
  }