.language-button {
  border: 2px solid white;
  background-color: transparent;
  color: white;
  padding: 7px;
  border-radius: 50%;
  transition: all 0.3s ease;
  font-size: 11px;
  margin-top: -9%;
  margin-left: 5%;
}

.language-button:hover {
  border-color: #ddd;
  color: #ddd;
}

.language-button.active {
  background-color: white;
  color: #225065;
}

.li{
  color: inherit;
  text-decoration: inherit;
}

.link-nav-chapa{
  color: #FFF;
  font-family: Public Sans;
  font-size: 75%;
  font-style: normal;
  font-weight: 300;
  line-height: 20px; /* 142.857% */
  letter-spacing: 2px;
  padding-left: 115px;
  margin-right: 5%;
}

.link-nav-chapab{
  color: #FFF;
  font-family: Public Sans;
  font-size: 12px;
  font-style: normal;
  font-weight: 200;
  line-height: 20px; /* 142.857% */
  letter-spacing: 1.8px;
  margin-right: 30px;
}

.liNav{
  color: inherit;
  text-decoration: inherit;
  margin-left: -150%;
}

.liNavEN{
  color: inherit;
  text-decoration: inherit;
  margin-left: -120%;
}


.liNav2{
  color: inherit;
  text-decoration: inherit;
  margin-left: -195%;
}

.liNav2EN{
  color: inherit;
  text-decoration: inherit;
  margin-left: -255%;
}

.liNav3{
  color: inherit;
  text-decoration: inherit;
  margin-left: -110%;
}

.liNav3EN{
  color: inherit;
  text-decoration: inherit;
  margin-left: -50%;
}

.botones {
  display: flex;
  flex-direction: row;
  gap: 5px;
  margin-left: -150%;
  margin-top: 12%;
}

@media (max-width: 800px) {
  .submenuNav-box {
    position: absolute;
    top: 0;
    left: 100%; 
    background: rgba(44, 45, 46, 0.45);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    list-style-type: none;
    display: flex;
    flex-direction: row;
    margin-left: 2%;
    width: 142%;
    height: 128%;
    text-align: left;
  }

  .nav-container{
    display: flex;
    justify-content: space-between;
    background-image:  #0c0c0c;
    background: linear-gradient(to bottom, rgba(12, 12, 12, 0.2), rgba(12, 12, 12, 0));
    padding-bottom: 2%;
  }

  .logo{
    width: 175px;
    height: 42px;
    margin-top: 10px;
  }
  
  .LogoNav{
    margin-left: -4%;
  }

  .nav-chapa{
    display: flex;
    align-items: left;
  } 

  .navbar-links-container{
    margin-right: 3rem;
    text-decoration: none;
    color:#bfc4d8;
    font-size: 15px;
    font-weight: 300;
  }

  .ul-nav{
    margin-top: 2%;
    display: flex;
    flex-direction: column;
    list-style:none;
    justify-content: flex-start;
    cursor: pointer;
    background-color: transparent;
    margin-right: 2%;
  }

  .ul-nav li {
    order: 1; 
  }

  .liNav1{
    color: inherit;
    text-decoration: inherit;
    display: flex;
    text-align: left;
  }

  .liNav1EN{
    color: inherit;
    text-decoration: inherit;
  }

  .liNav{
    color: inherit;
    text-decoration: inherit;
    display: flex;
    text-align: left;
  }
  
  .liNavEN{
    color: inherit;
    text-decoration: inherit;
  }

  .liNav2{
    color: inherit;
    text-decoration: inherit;
    display: flex;
    text-align: left;
  }
  
  .liNav2EN{
    color: inherit;
    text-decoration: inherit;
  }
  
  .liNav3{
    color: inherit;
    text-decoration: inherit;
    display: flex;
    text-align: left;
  }
  
  .liNav3EN{
    color: inherit;
    text-decoration: inherit;
  }

  .ul-menu  {
    position: absolute;
    top: 67px;
    left: 410px;
    background: rgba( 4, 68, 132, 0.45 );
    padding-top: 20px;
    padding-left: 32px;
    backdrop-filter: blur( 6px );
    -webkit-backdrop-filter: blur( 6px );
    list-style-type: none;
  }


  .ul-menu li {
    /* border-bottom: 0.01px solid #FFF;  */
    margin-bottom: 15px;
  }

  .link-nav-submenu{
    color: #FFF;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 200;
    line-height: 20px; /* 142.857% */
    letter-spacing: 1.8px;
    margin-right: 10px;
    margin-left: -20px;
  }
  
  .link-nav-submenuNuevo{
    color: #FFF;
    font-family: Public Sans;
    font-size: 12px;
    font-style: normal;
    font-weight: 200;
    line-height: 20px; /* 142.857% */
    letter-spacing: 1.8px;
    margin-right: 10px;
    margin-left: -20px;
    list-style-type: none;
  }

  .ul-menuNav {
    position: absolute; 
    top: 10.5%;
    left: 54%;
    background: rgba(4, 68, 132, 0.45);
    padding-left: 65px;
    padding-right: 38px;
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    list-style-type: none;
    margin-left: -7.1%;
    margin-top: 0.6%;
  }
  
  .ul-menuNav li {
    margin-top: 13px;
    margin-bottom: 15px;
  }
}

@media (min-width: 801px) and (max-width: 1280px) {
  .submenuNav-box {
      position: absolute;
      top: 0;
      left: 100%; 
      background: rgba(44, 45, 46, 0.45);
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      list-style-type: none;
      display: flex;
      flex-direction: row;
      margin-left: 2%;
      width: 142%;
      height: 128%;
      text-align: left;
    }

    .nav-container{
      display: flex;
      justify-content: space-between;
      background-image:  #0c0c0c;
      background: linear-gradient(to bottom, rgba(12, 12, 12, 0.2), rgba(12, 12, 12, 0));
      padding-bottom: 2%;
    }

    .logo{
      width: 250px;
      height: 60px;
      margin-top: 24px;
    }
    
    .LogoNav{
      margin-left: 80px;
      margin-right: 10px;
    }

    .nav-chapa{
      display: flex;
      align-items: left;
    } 

    .navbar-links-container{
      text-decoration: none;
      color:#bfc4d8;
      font-size: 15px;
      font-weight: 300;
    }

    .ul-nav{
      margin-top: 47px;
      display: flex;
      flex-direction: row;
      list-style:none;
      justify-content: flex-start;
      cursor: pointer;
      background-color: transparent;
      margin-right: 58%;
    }

    .ul-nav li {
      order: 1; 
    }

    .liNav1{
      color: inherit;
      text-decoration: inherit;
      margin-left: -320%;
      margin-right: -40%;
    }

    .liNav1EN{
      color: inherit;
      text-decoration: inherit;
      margin-left: -330%;
      margin-right: -40%;
    }

    .ul-menu  {
      position: absolute;
      top: 67px;
      left: 410px;
      background: rgba( 4, 68, 132, 0.45 );
      padding-top: 20px;
      padding-left: 32px;
      backdrop-filter: blur( 6px );
      -webkit-backdrop-filter: blur( 6px );
      list-style-type: none;
    }
  
  
    .ul-menu li {
      /* border-bottom: 0.01px solid #FFF;  */
      margin-bottom: 15px;
    }

    .link-nav-submenu{
      color: #FFF;
      font-family: Public Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 200;
      line-height: 20px; /* 142.857% */
      letter-spacing: 1.8px;
      margin-right: 10px;
      margin-left: -20px;
    }
    
    .link-nav-submenuNuevo{
      color: #FFF;
      font-family: Public Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 200;
      line-height: 20px; /* 142.857% */
      letter-spacing: 1.8px;
      margin-right: 10px;
      margin-left: -20px;
      list-style-type: none;
    }

    .ul-menuNav {
      position: absolute; 
      top: 10.5%;
      left: 54%;
      background: rgba(4, 68, 132, 0.45);
      padding-left: 65px;
      padding-right: 38px;
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      list-style-type: none;
      margin-left: -7.1%;
      margin-top: 0.6%;
    }
    
    .ul-menuNav li {
      margin-top: 13px;
      margin-bottom: 15px;
    }
}









@media (min-width: 1281px) and (max-width: 1679px) {
  .submenuNav-box {
      position: absolute;
      top: 0;
      left: 100%; 
      background: rgba(44, 45, 46, 0.45);
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      list-style-type: none;
      display: flex;
      flex-direction: row;
      margin-left: 2%;
      width: 142%;
      height: 128%;
      text-align: left;
    }

    .nav-container{
      display: flex;
      justify-content: space-between;
      background-image:  #0c0c0c;
      background: linear-gradient(to bottom, rgba(12, 12, 12, 0.2), rgba(12, 12, 12, 0));
      padding-bottom: 2%;
    }

    .logo{
      width: 65%;
      height: 65%;
      margin-top: 7%;
    }
    
    .LogoNav{
      margin-left: 2%;
    }

    .nav-chapa{
      display: flex;
      align-items: left;
    } 

    .navbar-links-container{
      margin-right: 3rem;
      text-decoration: none;
      color:#bfc4d8;
      font-size: 18px;
      font-weight: 400;
    }

    .ul-nav{
      margin-top: 5%;
      display: flex;
      flex-direction: row;
      list-style:none;
      justify-content: flex-start;
      cursor: pointer;
      background-color: transparent;
      margin-right: 55%;
    }

    .ul-nav li {
      order: 1; 
    }

    .liNav1{
      color: inherit;
      text-decoration: inherit;
      margin-left: -310%;
      margin-right: -40%;
    }

    .liNav1EN{
      color: inherit;
      text-decoration: inherit;
      margin-left: -330%;
      margin-right: -40%;
    }

    .ul-menu  {
      position: absolute;
      background: rgba( 4, 68, 132, 0.45 );
      padding-top: 20px;
      padding-left: 32px;
      backdrop-filter: blur( 6px );
      -webkit-backdrop-filter: blur( 6px );
      list-style-type: none;
      margin-top: 2%;
      margin-left: -12%;
    }
  
  
    .ul-menu li {
      /* border-bottom: 0.01px solid #FFF;  */
      margin-bottom: 15px;
    }

    .link-nav-submenu{
      color: #FFF;
      font-family: Public Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 200;
      line-height: 20px; /* 142.857% */
      letter-spacing: 1.8px;
      margin-right: 10px;
      margin-left: -20px;
    }
    
    .link-nav-submenuNuevo{
      color: #FFF;
      font-family: Public Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 200;
      line-height: 20px; /* 142.857% */
      letter-spacing: 1.8px;
      margin-right: 10px;
      margin-left: -20px;
      list-style-type: none;
    }

    .ul-menuNav {
      position: absolute; 
      top: 10.5%;
      left: 54%;
      background: rgba(4, 68, 132, 0.45);
      padding-left: 65px;
      padding-right: 38px;
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      list-style-type: none;
      margin-left: -7.1%;
      margin-top: 0.5%;
    }
    
    .ul-menuNav li {
      margin-top: 13px;
      margin-bottom: 15px;
    }
}








@media (min-width: 1680px) and (max-width: 1919px) {
  .submenuNav-box {
      position: absolute;
      top: 0;
      left: 100%; 
      background: rgba(44, 45, 46, 0.45);
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      list-style-type: none;
      display: flex;
      flex-direction: row;
      margin-left: 2%;
      width: 142%;
      height: 128%;
      text-align: left;
    }

    .nav-container{
      display: flex;
      justify-content: space-between;
      background-image:  #0c0c0c;
      background: linear-gradient(to bottom, rgba(12, 12, 12, 0.2), rgba(12, 12, 12, 0));
      padding-bottom: 2%;
    }

    .logo{
      width: 268px;
      height: 72px;
      margin-top: 22px;
    }
    
    .LogoNav{
      margin-left: 120px;
      margin-right: 250px;
    }

    .nav-chapa{
      display: flex;
      align-items: left;
    } 

    .navbar-links-container{
      margin-right: 3rem;
      text-decoration: none;
      color:#bfc4d8;
      font-size: 18px;
      font-weight: 400;
    }

    .ul-nav{
      margin-top: 5%;
      display: flex;
      flex-direction: row;
      list-style:none;
      justify-content: flex-start;
      cursor: pointer;
      background-color: transparent;
      margin-right: 55%;
    }

    .ul-nav li {
      order: 1; 
    }

    .liNav1{
      color: inherit;
      text-decoration: inherit;
      margin-left: -310%;
      margin-right: -40%;
    }

    .liNav1EN{
      color: inherit;
      text-decoration: inherit;
      margin-left: -330%;
      margin-right: -40%;
    }

    .ul-menu  {
      position: absolute;
      background: rgba( 4, 68, 132, 0.45 );
      padding-top: 20px;
      padding-left: 32px;
      backdrop-filter: blur( 6px );
      -webkit-backdrop-filter: blur( 6px );
      list-style-type: none;
      margin-top: 0.2%;
      margin-left: -5%;
    }
  
  
    .ul-menu li {
      /* border-bottom: 0.01px solid #FFF;  */
      margin-bottom: 15px;
    }

    .link-nav-submenu{
      color: #FFF;
      font-family: Public Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 200;
      line-height: 20px; /* 142.857% */
      letter-spacing: 1.8px;
      margin-right: 10px;
      margin-left: -20px;
    }
    
    .link-nav-submenuNuevo{
      color: #FFF;
      font-family: Public Sans;
      font-size: 12px;
      font-style: normal;
      font-weight: 200;
      line-height: 20px; /* 142.857% */
      letter-spacing: 1.8px;
      margin-right: 10px;
      margin-left: -20px;
      list-style-type: none;
    }

    .ul-menuNav {
      position: absolute; 
      top: 10.5%;
      left: 54%;
      background: rgba(4, 68, 132, 0.45);
      padding-left: 65px;
      padding-right: 38px;
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      list-style-type: none;
      margin-left: 0%;
      margin-top: -1.2%;
    }
    
    .ul-menuNav li {
      margin-top: 13px;
      margin-bottom: 15px;
    }
}









@media (min-width: 1920px) {
  .submenuNav-box {
      position: absolute;
      top: 0;
      left: 100%; 
      background: rgba(44, 45, 46, 0.45);
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      list-style-type: none;
      display: flex;
      flex-direction: row;
      margin-left: 2%;
      width: 142%;
      height: 128%;
      text-align: left;
    }

    .nav-container{
      display: flex;
      justify-content: space-between;
      background-image:  #0c0c0c;
      background: linear-gradient(to bottom, rgba(12, 12, 12, 0.2), rgba(12, 12, 12, 0));
      padding-bottom: 2%;
    }

    .logo{
      width: 400px;
      height: 95px;
      margin-top: 30px;
    }
    
    .LogoNav{
      margin-left: 100px;
      margin-right: 218px;
    }

    .nav-chapa{
      display: flex;
      align-items: left;
    } 

    .navbar-links-container{
      margin-right: 3rem;
      text-decoration: none;
      color:#bfc4d8;
      font-size: 22px;
      font-weight: 400;
      margin-top: 55px;
    }

    .ul-nav{
      display: flex;
      flex-direction: row;
      list-style: none;
      cursor: pointer;
      background-color: transparent;
      margin-right: 150px;
    }

    .ul-nav li {
      order: 1; 
    }

    .liNav1{
      color: inherit;
      text-decoration: inherit;
      margin-left: -260%;
      margin-right: 20%;
    }

    .liNav1EN{
      color: inherit;
      text-decoration: inherit;
      margin-left: -330%;
      margin-right: -40%;
    }

    .ul-menu  {
      position: absolute;
      background: rgba( 4, 68, 132, 0.45 );
      padding-top: 20px;
      padding-left: 32px;
      backdrop-filter: blur( 6px );
      -webkit-backdrop-filter: blur( 6px );
      list-style-type: none;
      margin-left: -110px;
      margin-top: 5px;
    }
  
  
    .ul-menu li {
      /* border-bottom: 0.01px solid #FFF;  */
      margin-bottom: 15px;
    }

    .link-nav-submenu{
      color: #FFF;
      font-family: Public Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 200;
      line-height: 20px; /* 142.857% */
      letter-spacing: 1.8px;
      margin-right: 20px;
      margin-left: -10px;
    }

    .link-nav-submenuNuevo{
      color: #FFF;
      font-family: Public Sans;
      font-size: 15px;
      font-style: normal;
      font-weight: 200;
      line-height: 20px; /* 142.857% */
      letter-spacing: 1.8px;
      margin-right: 10px;
      margin-left: -20px;
      list-style-type: none;
    }

    .ul-menuNav {
      position: absolute; 
      background: rgba(4, 68, 132, 0.45);
      padding-left: 65px;
      padding-right: 38px;
      backdrop-filter: blur(6px);
      -webkit-backdrop-filter: blur(6px);
      list-style-type: none;
      margin-left: -127px;
      margin-top: 5px;
    }
    
    .ul-menuNav li {
      margin-top: 13px;
      margin-bottom: 15px;
    }

    .botones {
      display: flex;
      flex-direction: row;
      gap: 5px;
      margin-left: -240%;
      margin-top: 23%;
    }
}