@media (max-width: 800px) {

  .contentContacto {
    position: absolute;
    width: 100%;
  }
  
  .contact-wrapper {
    display: flex;
    justify-content: left;
    margin-left: 20px;
    gap: 30px;
    flex-direction: column;
    margin-top: 600px;
  }
  
  .input-field {
    position: relative;
  }
  
  .input-field input,
  .input-field textarea {
    padding: 10px;
    border: 1px solid #ccc; 
    transition: border-color 0.3s;
    outline: none;
  }
  
  .input-field label {
    position: absolute;
    top: 10px;
    left: 10px;
    transition: 0.3s;
  }
  
  .input-field input:focus + label,
  .input-field textarea:focus + label {
    font-size: 12px;
    color: gray;
  }
  
  .input-field input:focus,
  .input-field textarea:focus {
    border-color: #5abdcf; 
  }
  
  .input-field label.hide {
    display: none;
  }
  
  .input-contacto {
    border-radius: 200px;
    background: #ececec;
    width: 300px;
    height: 20px;
    color: #878179;
    font-family: Public Sans;
  
  }
  
  .input-contacto2 {
    border-radius: 20px;
    background: #ececec;
    width: 300px;
    height: 105px;
    color: #878179;
    font-family: Public Sans;
  }
  
  .p-enviado {
    margin-top: -8%;
    font-size: 20px;
    color: red;
    font-weight: 400;
  }
  
  .form-contacto {
    display: flex;
    flex-direction: column;
    justify-content: left;
    align-items: flex-start;
    gap: 20px;
    width: 100%;
  }
  
  .input-field input.has-value + label,
  .input-field textarea.has-value + label {
    font-size: 12px;
    color: gray;
  }
  
  .nombre {
    color: #878179;
    font-size: 14px;
  }
  
  .btn-contacto {
      width: 160px;
      height: 36px;
      border-radius: 40px;
      background-color: transparent;
      border: 1.5px solid #225065;
      color: #225065;
      font-size: 9px;
      letter-spacing: 1.5px;
      text-align: center;
      font-weight: 600;
      display: flex;
      justify-content: left;
      padding-top: 12px;
      padding-left: 58px;
      transition: background-color 0.3s, color 0.3s; 
    }
    
    .btn-contacto:hover {
      background-color: #225065; 
      color: white; 
    }
  
    .spinner {
      font-size: 50px;
      position: absolute;
      top: 103%;
      left: 53%;
      color: #225065;
      animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
}









@media (min-width: 801px) and (max-width: 1280px) {
  .contentContacto {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  
  .contact-wrapper {
    display: flex;
    justify-content: left;
    margin-left: 130px;
    gap: 30px;
    flex-direction: column;
    margin-top: 40%;
  }
  
  .input-field {
    position: relative;
    margin-top: -2%;
    margin-left: -47%;
  }
  
  .input-field input,
  .input-field textarea {
    padding: 10px;
    border: 1px solid #ccc; 
    transition: border-color 0.3s;
    outline: none;
  }
  
  .input-field label {
    position: absolute;
    top: 10px;
    left: 10px;
    transition: 0.3s;
  }
  
  .input-field input:focus + label,
  .input-field textarea:focus + label {
    top: -20px;
    font-size: 12px;
    color: gray;
  }
  
  .input-field input:focus,
  .input-field textarea:focus {
    border-color: #5abdcf; 
  }
  
  .input-field label.hide {
    display: none;
  }
  
  .input-contacto {
    border-radius: 200px;
    background: #ececec;
    width: 510px;
    height: 15px;
    color: #878179;
    font-family: Public Sans;
  
  }
  
  .input-contacto2 {
    border-radius: 20px;
    background: #ececec;
    width: 510px;
    height: 105px;
    color: #878179;
    font-family: Public Sans;
  }
  
  .p-enviado {
    margin-top: -8%;
    font-size: 20px;
    color: red;
    font-weight: 400;
  }
  
  .form-contacto {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 2%;
  }
  
  .input-field input.has-value + label,
  .input-field textarea.has-value + label {
    top: -20px;
    font-size: 12px;
    color: gray;
  }
  
  .nombre {
    color: #878179;
    font-size: 14px;
  }
  
  .btn-contacto {
      width: 160px;
      height: 36px;
      border-radius: 40px;
      background-color: transparent;
      border: 1.5px solid #225065;
      color: #225065;
      font-size: 9px;
      letter-spacing: 1.5px;
      text-align: center;
      font-weight: 600;
      display: flex;
      justify-content: left;
      padding-top: 12px;
      padding-left: 58px;
      margin-left: 0.2%;
      margin-top: -2%;
      transition: background-color 0.3s, color 0.3s; 
    }
    
    .btn-contacto:hover {
      background-color: #225065; 
      color: white; 
    }
  
    .spinner {
      font-size: 50px;
      position: absolute;
      top: 103%;
      left: 53%;
      color: #225065;
      animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
}







@media (min-width: 1281px) and (max-width: 1919px) {
.contentContacto {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 2%;
}

.contact-wrapper {
  display: flex;
  justify-content: left;
  margin-left: 130px;
  gap: 30px;
  flex-direction: column;
  margin-top: 40%;
}

.input-field {
  position: relative;
  margin-top: -2%;
  margin-left: -47%;
}

.input-field input,
.input-field textarea {
  padding: 10px;
  border: 1px solid #ccc; 
  transition: border-color 0.3s;
  outline: none;
}

.input-field label {
  position: absolute;
  top: 10px;
  left: 10px;
  transition: 0.3s;
}

.input-field input:focus + label,
.input-field textarea:focus + label {
  top: -20px;
  font-size: 12px;
  color: gray;
}

.input-field input:focus,
.input-field textarea:focus {
  border-color: #5abdcf; 
}

.input-field label.hide {
  display: none;
}

.input-contacto {
  border-radius: 200px;
  background: #ececec;
  width: 550px;
  height: 20px;
  color: #878179;
  font-family: Public Sans;

}

.input-contacto2 {
  border-radius: 20px;
  background: #ececec;
  width: 550px;
  height: 120px;
  color: #878179;
  font-family: Public Sans;
}

.p-enviado {
  margin-top: -8%;
  font-size: 20px;
  color: red;
  font-weight: 400;
}

.form-contacto {
  display: flex;
  flex-direction: column;
  gap: 40px;
  margin-bottom: 2%;
}

.input-field input.has-value + label,
.input-field textarea.has-value + label {
  top: -20px;
  font-size: 12px;
  color: gray;
}

.nombre {
  color: #878179;
  font-size: 14px;
}

.btn-contacto {
    width: 160px;
    height: 36px;
    border-radius: 40px;
    background-color: transparent;
    border: 1.5px solid #225065;
    color: #225065;
    font-size: 9px;
    letter-spacing: 1.5px;
    text-align: center;
    font-weight: 600;
    display: flex;
    justify-content: left;
    padding-top: 12px;
    padding-left: 58px;
    margin-left: 0.2%;
    margin-top: -2%;
    transition: background-color 0.3s, color 0.3s; 
  }
  
  .btn-contacto:hover {
    background-color: #225065; 
    color: white; 
  }

  .spinner {
    font-size: 50px;
    position: absolute;
    top: 103%;
    left: 53%;
    color: #225065;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
}







@media (min-width: 1920px) {
  .contentContacto {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0%;
  }
  
  .contact-wrapper {
    display: flex;
    justify-content: left;
    margin-left: 130px;
    gap: 30px;
    flex-direction: column;
    margin-top: 34%;
  }
  
  .input-field {
    position: relative;
    margin-top: -1%;
    margin-left: -53%;
  }
  
  .input-field input,
  .input-field textarea {
    padding: 17px;
    border: 1px solid #ccc; 
    transition: border-color 0.3s;
    outline: none;
  }
  
  .input-field label {
    position: absolute;
    top: 10px;
    left: 10px;
    transition: 0.3s;
  }
  
  .input-field input:focus + label,
  .input-field textarea:focus + label {
    top: -20px;
    font-size: 12px;
    color: gray;
  }
  
  .input-field input:focus,
  .input-field textarea:focus {
    border-color: #5abdcf; 
  }
  
  .input-field label.hide {
    display: none;
  }
  
  .input-contacto {
    border-radius: 200px;
    background: #ececec;
    width: 650px;
    height: 20px;
    color: #878179;
    font-family: Public Sans;
  
  }
  
  .input-contacto2 {
    border-radius: 20px;
    background: #ececec;
    width: 650px;
    height: 120px;
    color: #878179;
    font-family: Public Sans;
  }
  
  .p-enviado {
    margin-top: -7%;
    font-size: 25px;
    color: red;
    font-weight: 400;
  }
  
  .form-contacto {
    display: flex;
    flex-direction: column;
    gap: 40px;
    margin-bottom: 3%;
  }
  
  .input-field input.has-value + label,
  .input-field textarea.has-value + label {
    top: -20px;
    font-size: 12px;
    color: gray;
  }
  
  .nombre {
    color: #878179;
    font-size: 14px;
  }
  
  .btn-contacto {
      width: 160px;
      height: 36px;
      border-radius: 40px;
      background-color: transparent;
      border: 1.5px solid #225065;
      color: #225065;
      font-size: 12px;
      letter-spacing: 1.5px;
      text-align: center;
      font-weight: 600;
      display: flex;
      justify-content: left;
      padding-top: 11px;
      padding-left: 54px;
      margin-left: 0.2%;
      margin-top: -1%;
      transition: background-color 0.3s, color 0.3s; 
    }
    
    .btn-contacto:hover {
      background-color: #225065;
      color: white; 
    }
  
    .spinner {
      font-size: 50px;
      position: absolute;
      top: 105%;
      left: 53%;
      color: #225065;
      animation: spin 1s linear infinite;
    }
    
    @keyframes spin {
      to {
        transform: rotate(360deg);
      }
    }
}