@media (max-width: 800px) {
  .tarjetas {
    display: flex;
    flex-direction: column;
    gap: 430px;
    margin-top: -7%;
    margin-left: 50px;
    margin-right: 50px;
  }

  .tarjeta{
    width: auto;
    height: auto;
    position: relative;
    perspective: 1000px;
    }
  
  .tarjeta:hover .contenido-trasero {
    transform: rotateY(0);
  }
  
  .contenido-frente {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 250px;
    height: 400px;
    border-radius: 15px;
  }

  .contenido-trasero {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 230px;
    height: 400px;
    border-radius: 15px;
    flex-direction: column;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .contenido-frente {
    background-color: #F4F4F4;
  }
  
  .contenido-trasero {
    background-color: #3A93A4;
    transform: rotateY(180deg);
    transition: transform 0.6s;
  }
  .vector-card{
    width: 110px;
    height: 110px;
  }

  .TituloTarjeta{
    font-size: 20px;
  }
  .TextoTarjeta {
    font-size: 15px;
    padding-left: 5%;
    padding-right: 5%;
  } 
}








@media (min-width: 801px) and (max-width: 1280px) {
  .tarjetas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 70px;
    margin-bottom: 25%;
  }
  .tarjeta{
    width: 200px;
    height: 200px;
    position: relative;
    perspective: 1000px;
    }
  
  .tarjeta:hover .contenido-trasero {
    transform: rotateY(0);
  }
  
  .contenido-frente {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 250px;
    height: 400px;
    border-radius: 15px;
  }

  .contenido-trasero {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 250px;
    height: 400px;
    border-radius: 15px;
    flex-direction: column;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .contenido-frente {
    background-color: #F4F4F4;
  }
  
  .contenido-trasero {
    background-color: #3A93A4;
    transform: rotateY(180deg);
    transition: transform 0.6s;
  }
  .vector-card{
    width: 110px;
    height: 110px;
  }

  .TituloTarjeta{
    font-size: 20px;
  }
  .TextoTarjeta {
    font-size: 15px;
    padding-left: 5%;
    padding-right: 5%;
  } 
}



@media (min-width: 1281px) and (max-width: 1549px) {
  .tarjetas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 75px;
    margin-bottom: 20%;
  }
  .tarjeta{
    width: 250px;
    height: 250px;
    position: relative;
    perspective: 1000px;
    }
  
  .tarjeta:hover .contenido-trasero {
    transform: rotateY(0);
  }
  
  .contenido-frente {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 250px;
    height: 400px;
    border-radius: 15px;
  }

  .contenido-trasero {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 250px;
    height: 400px;
    border-radius: 15px;
    flex-direction: column;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .contenido-frente {
    background-color: #F4F4F4;
  }
  
  .contenido-trasero {
    background-color: #3A93A4;
    transform: rotateY(180deg);
    transition: transform 0.6s;
  }
  .vector-card{
    width: 110px;
    height: 110px;
  }

  .TituloTarjeta{
    font-size: 20px;
  }
  .TextoTarjeta {
    font-size: 15px;
    padding-left: 5%;
    padding-right: 5%;
  }  
}





@media (min-width: 1550px) and (max-width: 1679px) {
  .tarjetas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 70px;
    margin-bottom: 25%;
  }
  .tarjeta{
    width: 200px;
    height: 200px;
    position: relative;
    perspective: 1000px;
    }
  
  .tarjeta:hover .contenido-trasero {
    transform: rotateY(0);
  }
  
  .contenido-frente {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 250px;
    height: 400px;
    border-radius: 15px;
  }

  .contenido-trasero {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 250px;
    height: 400px;
    border-radius: 15px;
    flex-direction: column;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .contenido-frente {
    background-color: #F4F4F4;
  }
  
  .contenido-trasero {
    background-color: #3A93A4;
    transform: rotateY(180deg);
    transition: transform 0.6s;
  }
  .vector-card{
    width: 110px;
    height: 110px;
  }

  .TituloTarjeta{
    font-size: 20px;
  }
  .TextoTarjeta {
    font-size: 15px;
    padding-left: 5%;
    padding-right: 5%;
  }  
}






@media (min-width: 1680px) and (max-width: 1919px) {
  .tarjetas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 120px;
    margin-bottom: 20%;
  }
  .tarjeta{
    width: 250px;
    height: 250px;
    position: relative;
    perspective: 1000px;
    }
  
  .tarjeta:hover .contenido-trasero {
    transform: rotateY(0);
  }
  
  .contenido-frente {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 250px;
    height: 400px;
    border-radius: 15px;
  }

  .contenido-trasero {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 250px;
    height: 400px;
    border-radius: 15px;
    flex-direction: column;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .contenido-frente {
    background-color: #F4F4F4;
  }
  
  .contenido-trasero {
    background-color: #3A93A4;
    transform: rotateY(180deg);
    transition: transform 0.6s;
  }
  .vector-card{
    width: 130px;
    height: 130px;
  }

  .TituloTarjeta{
    font-size: 20px;
  }
  .TextoTarjeta {
    font-size: 15px;
    padding-left: 5%;
    padding-right: 5%;
  }  
}






@media (min-width: 1920px) {
  .tarjetas {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 130px;
    margin-bottom: 20%;
  }
  .tarjeta{
    width: 300px;
    height: 300px;
    position: relative;
    perspective: 1000px;
    }
  
  .tarjeta:hover .contenido-trasero {
    transform: rotateY(0);
  }
  
  .contenido-frente {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 325px;
    height: 520px;
    border-radius: 15px;
  }

  .contenido-trasero {
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: absolute;
    backface-visibility: hidden;
    width: 325px;
    height: 520px;
    border-radius: 15px;
    flex-direction: column;
    color: white;
    padding-left: 10px;
    padding-right: 10px;
  }
  
  .contenido-frente {
    background-color: #F4F4F4;
  }
  
  .contenido-trasero {
    background-color: #3A93A4;
    transform: rotateY(180deg);
    transition: transform 0.6s;
  }
  .vector-card{
    width: 140px;
    height: 140px;
  }
  .TituloTarjeta{
    font-size: 25px;
  }
  .TextoTarjeta {
    font-size: 20px;
    padding-left: 5%;
    padding-right: 5%;
  }  
}