@media (max-width: 800px) {
  .Logimg1 {
    width: 250px;
    height: 65px;
    margin-top: 0%;
  }

  .LogisticaMobile{
    display: flex;
    justify-content: center;
    align-items: center;
  }

.home-containerLog {
background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  url("http://webcontent.cn-grupo.net/Media/Fotos/Logistica/PORTADA - DJI_0321.jpg");
background-size: cover;
background-repeat: no-repeat;
height: 600px;
background-position: 72% center;
}

.txt-redesLog {
display: flex;
flex-direction: row;
margin-top: 3%;
}

.txt-container-empresaLog {
display: flex;
flex-direction: column;
text-align: left;
margin-left: 7%;
margin-top: -2%;
margin-bottom: 15%;
}

.fotoLog {
width: 292px;
height: 188px;
}

.container-cards-logistica {
display: flex;
flex-direction: column;
gap: 20px;
margin-top: 0px;
margin-bottom: 20px;
}

.container-logistica {
display: flex;
flex-direction: column;
margin-top: 30px;
}

.text-logistica {
color: #6c6c6c;
font-weight: 400;
text-align: justify;
margin-left: 20px;
margin-right: 20px;
margin-bottom: 30px;
font-size: 14px;
}

.logologistica {
display: flex;
align-items: flex-start;
justify-content:flex-start;
margin-left: 20px;
margin-bottom: 30px;
}

.txt-empresa-spanLog {
font-weight: 600;
}

.separadorfoto {
margin-top: 30px;
background-image: url("http://webcontent.cn-grupo.net/Media/Fotos/Logistica/ANCHO WEB - 7S303513.png");
background-size: cover;
background-repeat: no-repeat;
height: 250px;
}

.container-txt-cardLog {
display: flex;
flex-direction: column;
justify-content: center;
text-align: left;
margin-left: 20px;
margin-right: 20px;
margin-top: 20px;
text-align: left;
}

.logistica-title-card {
color: #00448a;
font-family: Public Sans;
font-size: 20px;
font-style: normal;
font-weight: 700;
line-height: 69px;
}

.linea-logistica {
height: 1px;
border: none;
width: 80px;
background-color: #00448a;
margin-left: 0;
margin-top: -30px;
}

.txt-logistica-card {
color: #656565;
font-family: Public Sans;
font-size: 14px;
font-style: normal;
font-weight: 400;
line-height: 30px;
}

.titulo-logo-logistica {
display: flex;
flex-direction: row;
justify-content: space-between;
}

.vector-logistica {
height: 20px;
margin-top: 20px;
margin-right: 20px;
}

.vector-logistica2 {
height: 18px;
margin-top: 20px;
margin-right: 20px;
}

.vector-logistica2EN {
  height: 18px;
  margin-top: 20px;
  margin-right: 20px;
}

.vector-logistica3 {
height: 13px;
margin-top: 25px;
margin-right: 20px;
}

.card-contactoLog {
border-radius: 15px;
background: #f4f4f4;
width: auto;
height: auto;
flex-shrink: 0;
padding-bottom: 15px;
}

.title-direccionLog {
color: #00448a;
font-family: Public Sans;
font-size: 18px;
font-weight: 700;
line-height: 40px;
margin-bottom: -5%;
}

.txt-direccionLog {
color: #656565;
font-family: Public Sans;
font-size: 14px;
font-weight: 400;
line-height: 20px;
}

.title-direccionLog,
.txt-direccionLog {
margin-left: 10%;
margin-top: 30px;
}
}





@media (min-width: 801px) and (max-width: 1280px) {
  .Logimg1 {
    width: 250px;
    height: 65px;
    margin-top: 1%;
    margin-left: 1%;
  }

.home-containerLog {
background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
  url("http://webcontent.cn-grupo.net/Media/Fotos/Logistica/PORTADA - DJI_0321.jpg");
background-size: 100%;
background-repeat: no-repeat;
}

.txt-redesLog {
display: flex;
flex-direction: row;
margin-top: 3%;
}

.txt-container-empresaLog {
display: flex;
flex-direction: column;
text-align: left;
margin-left: 7%;
margin-top: -2%;
margin-bottom: 15%;
}

.fotoLog {
width: 450px;
height: 290px;
margin-top: 0px;
margin-right: 5%;
}

.container-cards-logistica {
display: flex;
flex-direction: row;
gap: 50px;
margin-top: 50px;
margin-right: 100px;
margin-bottom: 10%;
}

.container-logistica {
display: flex;
flex-direction: row;
}

.text-logistica {
color: #6c6c6c;
font-weight: 400;
text-align: left;
margin-left: 7.1%;
margin-right: 50px;
margin-bottom: 50px;
}

.container-logistica {
margin-top: 100px;
margin-left: 130px;
margin-right: 100px;
}

.logologistica {
margin-left: -40%;
}

.txt-empresa-spanLog {
font-weight: 600;
}

.separadorfoto {
margin-top: 100px;
background-image: url("http://webcontent.cn-grupo.net/Media/Fotos/Logistica/ANCHO WEB - 7S303513.png");
background-size: cover;
background-repeat: no-repeat;
height: 600px;
}

.container-txt-cardLog {
display: flex;
flex-direction: column;
justify-content: center;
text-align: left;
margin-left: 130px;
margin-top: 50px;
text-align: left;
}

.logistica-title-card {
color: #00448a;
font-family: Public Sans;
font-size: 25px;
font-style: normal;
font-weight: 700;
line-height: 69px;
}

.linea-logistica {
height: 1px;
border: none;
width: 80px;
background-color: #00448a;
margin-left: 0;
margin-top: -30px;
}

.txt-logistica-card {
color: #656565;
font-family: Public Sans;
font-size: 15px;
font-style: normal;
font-weight: 400;
line-height: 30px;
}

.titulo-logo-logistica {
display: flex;
flex-direction: row;
gap: 130px;
}

.vector-logistica {
height: 20px;
margin-top: 20px;
margin-left: 25px;
}

.vector-logistica2 {
height: 18px;
margin-top: 20px;
margin-left: 25px;
}

.vector-logistica2EN {
  height: 18px;
  margin-top: 20px;
  margin-left: -25px;
}

.vector-logistica3 {
height: 13px;
margin-top: 25px;
margin-left: 50px;
}

.card-contactoLog {
border-radius: 15px;
background: #f4f4f4;
width: 300px;
height: 140px;
flex-shrink: 0;
}

.title-direccionLog {
color: #00448a;
font-family: Public Sans;
font-size: 18px;
font-weight: 700;
line-height: 40px;
margin-bottom: -10%;
}

.txt-direccionLog {
color: #656565;
font-family: Public Sans;
font-size: 15px;
font-weight: 400;
line-height: 20px;
}

.title-direccionLog,
.txt-direccionLog {
margin-left: 10%;
margin-top: 30px;
}
}









@media (min-width: 1281px) and (max-width: 1679px) {
  .Logimg1 {
      width: 250px;
      height: 65px;
      margin-top: 2%;
      margin-left: -5%;
    }

.home-containerLog {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("http://webcontent.cn-grupo.net/Media/Fotos/Logistica/PORTADA - DJI_0321.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.txt-redesLog {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}

.txt-container-empresaLog {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 7.5%;
  margin-top: -1%;
  margin-bottom: 19%;
}

.fotoLog {
  width: 450px;
  height: 290px;
  margin-top: 0px;
  margin-right: 5%;
}

.container-cards-logistica {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 50px;
  margin-right: 100px;
  margin-bottom: 10%;
}

.container-logistica {
  display: flex;
  flex-direction: row;
}

.text-logistica {
  color: #6c6c6c;
  font-weight: 400;
  text-align: left;
  margin-left: 10%;
  margin-right: 50px;
  margin-bottom: 50px;
  font-size: 15px;
  font-family: Public Sans;
  line-height: 25px;

}

.container-logistica {
  margin-top: 100px;
  margin-left: 130px;
  margin-right: 100px;
}

.logologistica {
  margin-left: -40%;
}

.txt-empresa-spanLog {
  font-weight: 600;
}

.separadorfoto {
  margin-top: 100px;
  background-image: url("http://webcontent.cn-grupo.net/Media/Fotos/Logistica/ANCHO WEB - 7S303513.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
}

.container-txt-cardLog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 130px;
  margin-top: 50px;
  text-align: left;
}

.logistica-title-card {
  color: #00448a;
  font-family: Public Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 69px;
}

.linea-logistica {
  height: 1px;
  border: none;
  width: 80px;
  background-color: #00448a;
  margin-left: 0;
  margin-top: -30px;
}

.txt-logistica-card {
  color: #656565;
  font-family: Public Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.titulo-logo-logistica {
  display: flex;
  flex-direction: row;
  gap: 130px;
}

.vector-logistica {
  height: 20px;
  margin-top: 20px;
  margin-left: 25px;
}

.vector-logistica2 {
  height: 18px;
  margin-top: 20px;
  margin-left: 25px;
}

.vector-logistica2EN {
  height: 18px;
  margin-top: 20px;
  margin-left: -25px;
}

.vector-logistica3 {
  height: 13px;
  margin-top: 25px;
  margin-left: 50px;
}

.card-contactoLog {
  border-radius: 15px;
  background: #f4f4f4;
  width: 300px;
  height: 140px;
  flex-shrink: 0;
}

.title-direccionLog {
  color: #00448a;
  font-family: Public Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: -10%;
}

.txt-direccionLog {
  color: #656565;
  font-family: Public Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.title-direccionLog,
.txt-direccionLog {
  margin-left: 10%;
  margin-top: 30px;
}
}









@media (min-width: 1680px) and (max-width: 1919px) {
  .Logimg1 {
      width: 250px;
      height: 65px;
      margin-top: 1%;
      margin-left: -9%;
    }

.home-containerLog {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("http://webcontent.cn-grupo.net/Media/Fotos/Logistica/PORTADA - DJI_0321.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.txt-redesLog {
  display: flex;
  flex-direction: row;
  margin-top: 3%;
}

.txt-container-empresaLog {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 7%;
  margin-top: -2%;
  margin-bottom: 15%;
}

.fotoLog {
  width: 450px;
  height: 290px;
  margin-top: 0px;
  margin-right: 5%;
}

.container-cards-logistica {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 50px;
  margin-right: 100px;
  margin-bottom: 10%;
}

.container-logistica {
  display: flex;
  flex-direction: row;
}

.text-logistica {
  color: #6c6c6c;
  font-weight: 400;
  text-align: left;
  margin-left: 10%;
  margin-right: 50px;
  margin-bottom: 50px;
  font-size: 15px;
  font-family: Public Sans;
  line-height: 25px;

}

.container-logistica {
  margin-top: 100px;
  margin-left: 130px;
  margin-right: 100px;
}

.logologistica {
  margin-left: -40%;
}

.txt-empresa-spanLog {
  font-weight: 600;
}

.separadorfoto {
  margin-top: 100px;
  background-image: url("http://webcontent.cn-grupo.net/Media/Fotos/Logistica/ANCHO WEB - 7S303513.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 600px;
}

.container-txt-cardLog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 130px;
  margin-top: 50px;
  text-align: left;
}

.logistica-title-card {
  color: #00448a;
  font-family: Public Sans;
  font-size: 25px;
  font-style: normal;
  font-weight: 700;
  line-height: 69px;
}

.linea-logistica {
  height: 1px;
  border: none;
  width: 80px;
  background-color: #00448a;
  margin-left: 0;
  margin-top: -30px;
}

.txt-logistica-card {
  color: #656565;
  font-family: Public Sans;
  font-size: 15px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.titulo-logo-logistica {
  display: flex;
  flex-direction: row;
  gap: 130px;
}

.vector-logistica {
  height: 20px;
  margin-top: 20px;
  margin-left: 25px;
}

.vector-logistica2 {
  height: 18px;
  margin-top: 20px;
  margin-left: 25px;
}

.vector-logistica2EN {
  height: 18px;
  margin-top: 20px;
  margin-left: -25px;
}

.vector-logistica3 {
  height: 13px;
  margin-top: 25px;
  margin-left: 50px;
}

.card-contactoLog {
  border-radius: 15px;
  background: #f4f4f4;
  width: 300px;
  height: 140px;
  flex-shrink: 0;
}

.title-direccionLog {
  color: #00448a;
  font-family: Public Sans;
  font-size: 18px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: -10%;
}

.txt-direccionLog {
  color: #656565;
  font-family: Public Sans;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.title-direccionLog,
.txt-direccionLog {
  margin-left: 10%;
  margin-top: 30px;
}
}









@media (min-width: 1920px) {
  .Logimg1 {
      width: 300px;
      height: 80px;
      margin-top: 5px;
      margin-left: -28%;
    }

.home-containerLog {
  background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)),
    url("http://webcontent.cn-grupo.net/Media/Fotos/Logistica/PORTADA - DJI_0321.jpg");
  background-size: 100%;
  background-repeat: no-repeat;
}

.txt-redesLog {
  display: flex;
  flex-direction: row;
  margin-top: 10%;
}

.txt-container-empresaLog {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 7.5%;
  margin-top: -3%;
  margin-bottom: 19%;
}

.fotoLog {
  width: 650px;
  height: 388px;
  margin-top: 0px;
}

.container-cards-logistica {
  display: flex;
  flex-direction: row;
  gap: 50px;
  margin-top: 50px;
  margin-right: 100px;
  margin-bottom: 10%;
}

.container-logistica {
  display: flex;
  flex-direction: row;
}

.text-logistica {
  color: #6c6c6c;
  font-weight: 400;
  text-align: left;
  margin-right: 50px;
  margin-bottom: 50px;
  font-size: 20px;
  font-family: Public Sans;
  line-height: 30px;
}

.container-logistica {
  margin-top: 100px;
  margin-left: 13%;
  margin-right: 12%;
}

.logologistica {
  margin-left: -200px;
}

.txt-empresa-spanLog {
  font-weight: 600;
}

.separadorfoto {
  margin-top: 100px;
  background-image: url("http://webcontent.cn-grupo.net/Media/Fotos/Logistica/ANCHO WEB - 7S303513.png");
  background-size: cover;
  background-repeat: no-repeat;
  height: 800px;
}

.container-txt-cardLog {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: left;
  margin-left: 130px;
  margin-top: 50px;
  text-align: left;
}

.logistica-title-card {
  color: #00448a;
  font-family: Public Sans;
  font-size: 35px;
  font-style: normal;
  font-weight: 700;
  line-height: 69px;
}

.linea-logistica {
  height: 1px;
  border: none;
  width: 80px;
  background-color: #00448a;
  margin-left: 0;
  margin-top: -30px;
}

.txt-logistica-card {
  color: #656565;
  font-family: Public Sans;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 30px;
}

.titulo-logo-logistica {
  display: flex;
  flex-direction: row;
  gap: 130px;
}

.vector-logistica {
  height: 22px;
  margin-top: 20px;
  margin-left: 20px;
}

.vector-logistica2 {
  height: 21px;
  margin-top: 20px;
  margin-left: 27px;
}

.vector-logistica2EN {
  height: 21px;
  margin-top: 20px;
  margin-left: -50px;
}

.vector-logistica3 {
  height: 16px;
  margin-top: 23px;
  margin-left: 60px;
}

.card-contactoLog {
  border-radius: 15px;
  background: #f4f4f4;
  width: 330px;
  height: 150px;
  flex-shrink: 0;
}

.title-direccionLog {
  color: #00448a;
  font-family: Public Sans;
  font-size: 25px;
  font-weight: 700;
  line-height: 40px;
  margin-bottom: -10%;
}

.txt-direccionLog {
  color: #656565;
  font-family: Public Sans;
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
}

.title-direccionLog,
.txt-direccionLog {
  margin-left: 10%;
  margin-top: 40px;
}
}
