@media (max-width: 800px) {
  .home-container8 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("http://webcontent.cn-grupo.net/Media/Fotos/Chile/PORTADA - DSC04714-HDR.png");
    background-size: cover;
    height: 650px;
  }

  /*.redes-container-empresa-chile {
    position: fixed;
    right: 0%;
    display: flex;
    flex-direction: column;
    margin-top: 170px;
    font-size: 25px;
    color: #fff;
    height: 73px;
    padding-top: 12px;
    padding-right: 10px;
    padding-left: 10px;
    background: rgba(255, 11, 26, 0.6);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    gap: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }*/

  /*.redes-container-empresa-chile{
    position: fixed;
    right: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
      margin-top: 190px;
      font-size: 150%;
      color: #FFF;
      height: 15%;
      padding-right: 1%;
      padding-left:1%;
      padding-top: 1.5%;
      padding-bottom: 1%;
      background: rgba(255, 11, 26, 0.6);
      backdrop-filter: blur( 6px );
      -webkit-backdrop-filter: blur( 6px );
      gap: 10%;
      z-index: 3;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }*/
    .redes-container-empresa-chile{
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
      left: 0px;
      top: 490px;
      font-size: 25px;
      color: #FFF;
      height: auto;
      width: 35px;
      padding-right: 1%;
      padding-left:1%;
      padding-top:20px;
      padding-bottom: 20px;
      background: rgba(255, 11, 26, 0.6);
      backdrop-filter: blur( 6px );
      -webkit-backdrop-filter: blur( 6px );
      gap: 10%;
      z-index: 3;
    }

  .imgchile {
    width: 315px;
    height: 203px;
  }

  .mapaChile {
    width: 302px;
    height: 370px;
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .MapaChile-Mobile{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .txt-redesChile {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .txt-container-empresaChile {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 18%;
  }

  .chile-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.05px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .container-chile {
    display: flex;
    flex-direction: column;
    margin-top: 30px;
    margin-bottom: 30px;
    margin-left: 20px;
    margin-right: 20px;
  }

  .logochile {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    width: 250px;
    margin-bottom: 20px;
  }

  .p-chile1 {
    text-align: justify;
    color: #6c6c6c;
    font-size: 14px;
    font-weight: 400;
    line-height: 25px;
  }

  .txt-empresa-spanChile {
    font-weight: 600;
  }

  .container-txt-cardChile {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    text-align: left;
    margin-top: 50px;
    text-align: left;
    background-color: #F4F4F4;
  }

  .container-txt-cardChile1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
  }

  .chile-title-card {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
    margin-left: 20px;
    margin-top: 30px;
  }

  .linea-chile {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-top: -30px;
    margin-left: 20px;
  }

  .chile-title-card1 {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .linea-chile1 {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-chile-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .container-cards-chile {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 10px;
    margin-bottom: 30px;
  }

  .card-contactoChile {
    border-radius: 15px;
    background: #f4f4f4;
    width: auto;
    height: auto;
    flex-shrink: 0;
    padding-bottom: 15px;
  }

  .titulo-logo-chile {
    display: flex;
    flex-direction: row;
  }

  .title-direccionChile,
  .txt-direccionChile {
    margin-left: 37px;
    margin-top: 20px;
  }

  .title-direccionChile {
    color: #00448a;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: -10px;
  }

  .txt-direccionChile {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .vector-chile {
    height: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 20px;
  }

  .vector-chile2 {
    height: 18px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 20px;
  }

  .vector-chile3 {
    height: 13px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: 20px;
  }

  .banner-chile{
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/Chile/ANCHO WEB - DSC04722-HDR-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 300PX;
    background-position: 50% center;
  }
}







@media (min-width: 801px) and (max-width: 1280px) {
  .home-container8 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("http://webcontent.cn-grupo.net/Media/Fotos/Chile/PORTADA - DSC04714-HDR.png");
    background-size: cover;
    height: 600px;
  }

  /*.redes-container-empresa-chile {
    position: fixed;
    right: 0%;
    display: flex;
    flex-direction: column;
    margin-top: 170px;
    font-size: 25px;
    color: #fff;
    height: 73px;
    padding-top: 12px;
    padding-right: 10px;
    padding-left: 10px;
    background: rgba(255, 11, 26, 0.6);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    gap: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }*/

  .redes-container-empresa-chile{
    position: fixed;
    right: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
      margin-top: 190px;
      font-size: 150%;
      color: #FFF;
      height: 15%;
      padding-right: 1%;
      padding-left:1%;
      padding-top: 1.5%;
      padding-bottom: 1%;
      background: rgba(255, 11, 26, 0.6);
      backdrop-filter: blur( 6px );
      -webkit-backdrop-filter: blur( 6px );
      gap: 10%;
      z-index: 3;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

  .imgchile {
    width: 450px;
    height: 290px;
  }

  .mapaChile {
    width: 504px;
    height: 617px;
    margin-left: 35%;
    margin-bottom: 8%;
    margin-top: 5%;
  }

  .txt-redesChile {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .txt-container-empresaChile {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 17%;
  }

  .chile-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.05px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .container-chile {
    display: flex;
    flex-direction: row;
    margin-top: 8%;
    margin-left: 170px;
    margin-right: 150px;
    margin-bottom: 8%;
  }
  .logochile {
    width: 250px;
    margin-left: -49%;
  }

  .p-chile1 {
    text-align: left;
    color: #6c6c6c;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    margin-right: 10%;
  }

  .txt-empresa-spanChile {
    font-weight: 600;
  }

  .container-txt-cardChile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-top: 50px;
    text-align: left;
    background-color: #F4F4F4;
  }

  .container-txt-cardChile1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
  }

  .chile-title-card {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
    margin-left: 10%;
    margin-top: 5%;
  }

  .linea-chile {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
    margin-left: 10%;
  }

  .chile-title-card1 {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .linea-chile1 {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-chile-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .container-cards-chile {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
    margin-right: 100px;
    margin-bottom: 10%;
  }

  .card-contactoChile {
    border-radius: 15px;
    background: #f4f4f4;
    width: 300px;
    height: 140px;
    flex-shrink: 0;
  }

  .titulo-logo-chile {
    display: flex;
    flex-direction: row;
  }

  .title-direccionChile,
  .txt-direccionChile {
    margin-left: 37px;
    margin-top: 20px;
  }

  .title-direccionChile {
    color: #00448a;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: -10px;
  }

  .txt-direccionChile {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .vector-chile {
    height: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 10px;
  }

  .vector-chile2 {
    height: 18px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 10px;
  }

  .vector-chile3 {
    height: 13px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: 10px;
  }

  .banner-chile{
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/Chile/ANCHO WEB - DSC04722-HDR-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 780PX;
    width: 100%
  }
}








@media (min-width: 1281px) and (max-width: 1549px) {
  .home-container8 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("http://webcontent.cn-grupo.net/Media/Fotos/Chile/PORTADA - DSC04714-HDR.png");
    background-size: cover;
    height: 700px;
  }

  /*.redes-container-empresa-chile {
    position: fixed;
    right: 0%;
    display: flex;
    flex-direction: column;
    margin-top: 170px;
    font-size: 25px;
    color: #fff;
    height: 73px;
    padding-top: 12px;
    padding-right: 10px;
    padding-left: 10px;
    background: rgba(255, 11, 26, 0.6);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    gap: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }*/

  .redes-container-empresa-chile{
    position: fixed;
    right: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
      margin-top: 190px;
      font-size: 150%;
      color: #FFF;
      height: 15%;
      padding-right: 1%;
      padding-left:1%;
      padding-top: 1.5%;
      padding-bottom: 1%;
      background: rgba(255, 11, 26, 0.6);
      backdrop-filter: blur( 6px );
      -webkit-backdrop-filter: blur( 6px );
      gap: 10%;
      z-index: 3;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

  .imgchile {
    width: 450px;
    height: 290px;
  }

  .mapaChile {
    width: 504px;
    height: 617px;
    margin-left: 35%;
    margin-bottom: 8%;
    margin-top: 5%;
  }

  .txt-redesChile {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .txt-container-empresaChile {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 8%;
    margin-top: 17%;
  }

  .chile-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.05px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .container-chile {
    display: flex;
    flex-direction: row;
    margin-top: 8%;
    margin-left: 170px;
    margin-right: 150px;
    margin-bottom: 8%;
  }
  .logochile {
    width: 250px;
    margin-left: -66.5%;
  }

  .p-chile1 {
    text-align: left;
    color: #6c6c6c;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    margin-right: 10%;
  }

  .txt-empresa-spanChile {
    font-weight: 600;
  }

  .container-txt-cardChile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-top: 50px;
    text-align: left;
    background-color: #F4F4F4;
  }

  .container-txt-cardChile1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
  }

  .chile-title-card {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
    margin-left: 10%;
    margin-top: 5%;
  }

  .linea-chile {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
    margin-left: 10%;
  }

  .chile-title-card1 {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .linea-chile1 {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-chile-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .container-cards-chile {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
    margin-right: 100px;
    margin-bottom: 10%;
  }

  .card-contactoChile {
    border-radius: 15px;
    background: #f4f4f4;
    width: 320px;
    height: 140px;
    flex-shrink: 0;
  }

  .titulo-logo-chile {
    display: flex;
    flex-direction: row;
  }

  .title-direccionChile,
  .txt-direccionChile {
    margin-left: 50px;
    margin-top: 25px;
  }

  .title-direccionChile {
    color: #00448a;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: -10px;
  }

  .txt-direccionChile {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .vector-chile {
    height: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 10px;
  }

  .vector-chile2 {
    height: 18px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 10px;
  }

  .vector-chile3 {
    height: 13px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: 10px;
  }

  .banner-chile{
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/Chile/ANCHO WEB - DSC04722-HDR-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 900PX;
    width: 100%
  }
}







@media (min-width: 1550px) and (max-width: 1919px) {
  .home-container8 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("http://webcontent.cn-grupo.net/Media/Fotos/Chile/PORTADA - DSC04714-HDR.png");
    background-size: cover;
    height: 700px;
  }

  /*.redes-container-empresa-chile {
    position: fixed;
    right: 0%;
    display: flex;
    flex-direction: column;
    margin-top: 170px;
    font-size: 25px;
    color: #fff;
    height: 73px;
    padding-top: 12px;
    padding-right: 10px;
    padding-left: 10px;
    background: rgba(255, 11, 26, 0.6);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    gap: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }*/

  .redes-container-empresa-chile{
    position: fixed;
    right: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
      margin-top: 190px;
      font-size: 150%;
      color: #FFF;
      height: 15%;
      padding-right: 1%;
      padding-left:1%;
      padding-top: 1.5%;
      padding-bottom: 1%;
      background: rgba(255, 11, 26, 0.6);
      backdrop-filter: blur( 6px );
      -webkit-backdrop-filter: blur( 6px );
      gap: 10%;
      z-index: 3;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

  .imgchile {
    width: 450px;
    height: 290px;
  }

  .mapaChile {
    width: 504px;
    height: 617px;
    margin-left: 35%;
    margin-bottom: 8%;
    margin-top: 5%;
  }

  .txt-redesChile {
    display: flex;
    flex-direction: row;
    margin-top: -15%;
  }

  .txt-container-empresaChile {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7%;
    margin-top: 17%;
  }

  .chile-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.05px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .container-chile {
    display: flex;
    flex-direction: row;
    margin-top: 8%;
    margin-left: 170px;
    margin-right: 150px;
    margin-bottom: 8%;
  }
  .logochile {
    width: 250px;
    margin-left: -56%;
  }

  .p-chile1 {
    text-align: left;
    color: #6c6c6c;
    font-size: 15px;
    font-weight: 400;
    line-height: 25px;
    margin-right: 10%;
  }

  .txt-empresa-spanChile {
    font-weight: 600;
  }

  .container-txt-cardChile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-top: 50px;
    text-align: left;
    background-color: #F4F4F4;
  }

  .container-txt-cardChile1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
  }

  .chile-title-card {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
    margin-left: 10%;
    margin-top: 5%;
  }

  .linea-chile {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
    margin-left: 10%;
  }

  .chile-title-card1 {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .linea-chile1 {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-chile-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .container-cards-chile {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
    margin-right: 100px;
    margin-bottom: 10%;
  }

  .card-contactoChile {
    border-radius: 15px;
    background: #f4f4f4;
    width: 320px;
    height: 140px;
    flex-shrink: 0;
  }

  .titulo-logo-chile {
    display: flex;
    flex-direction: row;
  }

  .title-direccionChile,
  .txt-direccionChile {
    margin-left: 50px;
    margin-top: 25px;
  }

  .title-direccionChile {
    color: #00448a;
    font-family: Public Sans;
    font-size: 18px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: -10px;
  }

  .txt-direccionChile {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
  }

  .vector-chile {
    height: 20px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 10px;
  }

  .vector-chile2 {
    height: 18px;
    margin-top: 20px;
    margin-left: auto;
    margin-right: 10px;
  }

  .vector-chile3 {
    height: 13px;
    margin-top: 25px;
    margin-left: auto;
    margin-right: 10px;
  }

  .banner-chile{
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/Chile/ANCHO WEB - DSC04722-HDR-2.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    height: 900PX;
    width: 100%
  }
}














@media (min-width: 1920px) {

  .home-container8 {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("http://webcontent.cn-grupo.net/Media/Fotos/Chile/PORTADA - DSC04714-HDR.png");
    background-size: 100%;
    background-repeat: no-repeat;
  }

  /*.redes-container-empresa-chile {
    position: fixed;
    right: 0%;
    display: flex;
    flex-direction: column;
    margin-top: 170px;
    font-size: 25px;
    color: #fff;
    height: 73px;
    padding-top: 12px;
    padding-right: 10px;
    padding-left: 10px;
    background: rgba(255, 11, 26, 0.6);
    backdrop-filter: blur(6px);
    -webkit-backdrop-filter: blur(6px);
    gap: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
  }*/

  .redes-container-empresa-chile{
    position: fixed;
    right: 0%;
    display: flex;
    flex-direction: column;
    justify-content: center;
      margin-top: 230px;
      font-size: 150%;
      color: #FFF;
      height: 12%;
      padding-right: 1%;
      padding-left:1%;
      padding-top: 1.5%;
      padding-bottom: 1%;
      background: rgba(255, 11, 26, 0.6);
      backdrop-filter: blur( 6px );
      -webkit-backdrop-filter: blur( 6px );
      gap: 10%;
      z-index: 3;
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }

  .imgchile {
    width: 650px;
    height: 388px;
  }

  .mapaChile {
    width: 648px;
    height: 793px;
    margin-left: 34%;
    margin-bottom: 8%;
    margin-top: 5%;
  }

  .txt-redesChile {
    display: flex;
    flex-direction: row;
    margin-top: 9%;
  }

  .txt-container-empresaChile {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-left: 7.5%;
    margin-top: -2%;
    margin-bottom: 16.6%;
  }

  .chile-title {
    font-size: 40px;
    font-weight: 600;
    line-height: 25px;
    letter-spacing: 0.05px;
    color: #fff;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
  }

  .container-chile {
    display: flex;
    flex-direction: row;
    margin-top: 100px;
    margin-left: 13%;
    margin-right: 12%;
  }

  .logochile {
    width: 250px;
    margin-left: -67%;
  }

  .p-chile1 {
    text-align: left;
    color: #6c6c6c;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-right: 10%;
  }

  .txt-empresa-spanChile {
    font-weight: 600;
  }

  .container-txt-cardChile {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-top: 50px;
    text-align: left;
    background-color: #F4F4F4;
  }

  .container-txt-cardChile1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    margin-left: 130px;
    margin-top: 50px;
    text-align: left;
  }

  .chile-title-card {
    color: #00448a;
    font-family: Public Sans;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
    margin-left: 10%;
    margin-top: 5%;
  }

  .linea-chile {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
    margin-left: 10%;
  }

  .chile-title-card1 {
    color: #00448a;
    font-family: Public Sans;
    font-size: 35px;
    font-style: normal;
    font-weight: 700;
    line-height: 69px;
  }

  .linea-chile1 {
    height: 1px;
    border: none;
    width: 80px;
    background-color: #00448a;
    margin-left: 0;
    margin-top: -30px;
  }

  .txt-chile-card {
    color: #656565;
    font-family: Public Sans;
    font-size: 15px;
    font-style: normal;
    font-weight: 400;
    line-height: 30px;
  }

  .container-cards-chile {
    display: flex;
    flex-direction: row;
    gap: 50px;
    margin-top: 50px;
    margin-right: 100px;
    margin-bottom: 10%;
  }

  .card-contactoChile {
    border-radius: 15px;
    background: #f4f4f4;
    width: 350px;
    height: 140px;
    flex-shrink: 0;
  }

  .titulo-logo-chile {
    display: flex;
    flex-direction: row;
  }

  .title-direccionChile,
  .txt-direccionChile {
    margin-left: 10%;
    margin-top: 30px;
  }

  .title-direccionChile {
    color: #00448a;
    font-family: Public Sans;
    font-size: 25px;
    font-weight: 700;
    line-height: 40px;
    margin-bottom: -10%;
  }

  .txt-direccionChile {
    color: #656565;
    font-family: Public Sans;
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
    margin-top: 35px;
  }

  .vector-chile {
    height: 22px;
    margin-top: 18px;
    margin-left: auto;
    margin-right: 15px;
  }

  .vector-chile2 {
    height: 22px;
    margin-top: 18px;
    margin-left: auto;
    margin-right: 15px;
  }

  .vector-chile3 {
    height: 16px;
    margin-top: 23px;
    margin-left: auto;
    margin-right: 15px;
  }

  .banner-chile{
    background: url("http://webcontent.cn-grupo.net/Media/Fotos/Chile/ANCHO WEB - DSC04722-HDR-2.jpg");
    height: 1200PX;
    width: 100%;
    background-size: cover;
  }
}
